import { MetaInterface, ProductsInfoInterface } from "../product/productState"
import { UserInterface } from "../user/userState"

export interface OrderInitialInterface {
  orders: OrdersInterface | null
  order: OrderInterface | null
  is_loading: boolean
  is_fetching: boolean
}

export const OrderinitialState: OrderInitialInterface = {
  orders: null,
  order: null,
  is_loading: false,
  is_fetching: false,
}

export interface OrdersInterface {
  meta: MetaInterface
  orders: OrderInterface[]
}

export interface OrderInterface {
  _id: string
  deliveryAddress: DeliveryAddressInterface
  user: UserInterface
  orderItems: OrderItemsInterface[]
  paymentMethod: string
  taxPrice: number
  itemsPrice: number
  deliveryPrice: number
  totalPrice: number
  isPaid: boolean
  isDelivered: string
  createdAt: string
}

export interface DeliveryAddressInterface {
  address: string
  city: string
  country: string
}

export interface OrderItemsInterface {
  _id: string
  name: string
  qty: number
  image: string
  price: number
  product: ProductsInfoInterface
}
