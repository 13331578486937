/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { CategoryInitialState } from "./categoryState"
import { authHeader } from "../../utils/headers"
import config from "../../utils/config"
import { toast } from "react-hot-toast"
import { store } from "../../app/store"
import { reset } from "../authentication/authenticationSlice"

let url = config.liveUrl

export const post_category = createAsyncThunk(
  "category/post_category",
  async (
    payload: {
      name: string
      // subCategory: string[]
      description: string
      gender: string
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.post(`${url}/categories`, payload, {
        headers: authHeader(token ? token : "123"),
      })

      toast.success(response.data.message, {
        position: "top-right",
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const delete_category = createAsyncThunk(
  "category/delete_category",
  async (
    payload: {
      id: string
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.delete(
        `${url}/categories/${payload.id}`,
        {
          headers: authHeader(token ? token : "123"),
        },
      )
      // console.log("id", payload.id);
      
      toast.success(response.data.message, {
        position: "top-right",
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const update_category = createAsyncThunk(
  "category/update_category",
  async (
    payload: {
      name: string
      // subCategory: string[]
      slug: string
      description: string
      gender: string
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.put(
        `${url}/categories/${payload.slug}`,
        payload,
        {
          headers: authHeader(token ? token : "123"),
        },
      )

      toast.success(response.data.message, {
        position: "top-right",
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

// sub categories starts
export const post_subcategories = createAsyncThunk(
  "category/post_subcategories",
  async (
    payload: {
      name: string
      category: string
      description: string
      gender: string
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.post(`${url}/categories/sub-categories`, payload, {
        headers: authHeader(token ? token : "123"),
      })

      toast.success("subCategory created", {
        position: "top-right",
      })

      return response.data
    } catch (error: any) {
      console.log(error);
      
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const delete_sub_category = createAsyncThunk(
  "category/delete_sub_category",
  async (
    payload: {
      id: string
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.delete(
        `${url}/categories/sub-category/${payload.id}`,
        {
          headers: authHeader(token ? token : "123"),
        },
      )
      // console.log("id", payload.id);
      
      toast.success(response.data.message, {
        position: "top-right",
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const update_subcategories = createAsyncThunk(
  "category/update_subcategories",
  async (
    payload: {
      name: string
      category: string | undefined
      description: string
      gender: string
      slug: string
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.put(
        `${url}/categories/sub-categories/${payload.slug}`,
        payload,
        {
          headers: authHeader(token ? token : "123"),
        },
      )

      toast.success(response.data.message, {
        position: "top-right",
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const get_subcategories = createAsyncThunk(
  "category/get_subcategories",
  async (
    payload: {
      id: any
    },
    thunkAPI,
  ) => {
    if (!payload.id) {
      console.log("Category ID is required")
    }
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.get(`${url}/categories/sub-categories?category=${payload.id}`, {
        headers: authHeader(token ? token : "123"),
      })
      // console.log(response);
      return response.data

    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      // console.log(error);
      
      return message
    }
  },
)

export const update_subcategory_status = createAsyncThunk(
  "category/update_subcategory_status",
  async (
    payload: {
      slug: string
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.put(
        `${url}/sub_categories/status/${payload.slug}`,
        payload,
        {
          headers: authHeader(token ? token : "123"),
        },
      )

      toast.success(response.data.message, {
        position: "top-right",
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

// sub categories ends

export const get_categories = createAsyncThunk(
  "category/get_categories",
  async (_, thunkAPI) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.get(`${url}/categories`, {
        headers: authHeader(token ? token : "123"),
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const update_category_status = createAsyncThunk(
  "category/update_category_status",
  async (
    payload: {
      slug: string
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.put(
        `${url}/categories/status/${payload.slug}`,
        payload,
        {
          headers: authHeader(token ? token : "123"),
        },
      )

      toast.success(response.data.message, {
        position: "top-right",
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const { reducer: CategoryReducer, actions } = createSlice({
  name: "category",
  initialState: CategoryInitialState,
  reducers: {
    resetCategories: (state) => {
      state.is_loading = false
      state.categories = null
      state.category = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(post_category.fulfilled, (state, action) => {
      state.category = action.payload.data
      state.is_loading = false
    })
    builder.addCase(post_category.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(post_category.rejected, (state, action) => {
      state.is_loading = false
    })
    builder.addCase(delete_category.fulfilled, (state, action) => {
      state.category = action.payload.data
      state.is_loading = false
    })
    builder.addCase(delete_category.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(delete_category.rejected, (state, action) => {
      state.is_loading = false
    })
    builder.addCase(update_category.fulfilled, (state, action) => {
      state.category = action.payload.data
      state.is_loading = false
    })
    builder.addCase(update_category.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(update_category.rejected, (state, action) => {
      state.is_loading = false
    })
    builder.addCase(get_categories.fulfilled, (state, action) => {
      state.categories = action.payload.data
      state.is_fetching = false
    })
    builder.addCase(get_categories.pending, (state, action) => {
      state.is_fetching = true
    })
    builder.addCase(get_categories.rejected, (state, action) => {
      state.is_fetching = false
    })
    builder.addCase(update_category_status.fulfilled, (state, action) => {
      state.category = action.payload.data
      state.is_loading = false
    })
    builder.addCase(update_category_status.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(update_category_status.rejected, (state, action) => {
      state.is_loading = false
    })
    builder.addCase(post_subcategories.fulfilled, (state, action) => {
      state.sub_each_subCategory = action.payload.data
      state.is_loading = false
    })
    builder.addCase(post_subcategories.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(post_subcategories.rejected, (state, action) => {
      state.is_loading = false
    })
    builder.addCase(delete_sub_category.fulfilled, (state, action) => {
      state.sub_each_subCategory = action.payload.data
      state.is_loading = false
    })
    builder.addCase(delete_sub_category.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(delete_sub_category.rejected, (state, action) => {
      state.is_loading = false
    })
    builder.addCase(update_subcategories.fulfilled, (state, action) => {
      state.sub_each_subCategory = action.payload.data
      state.is_loading = false
    })
    builder.addCase(update_subcategories.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(update_subcategories.rejected, (state, action) => {
      state.is_loading = false
    })
    builder.addCase(get_subcategories.fulfilled, (state, action) => {
      state.sub_category = action.payload.data
      state.is_loading = false
      state.is_fetching = false
      
    })
    builder.addCase(get_subcategories.pending, (state, action) => {
      state.is_loading = true
      state.is_fetching = true
    })
    builder.addCase(get_subcategories.rejected, (state, action) => {
      state.is_loading = false
      state.is_fetching = false
    })
    builder.addCase(update_subcategory_status.fulfilled, (state, action) => {
      state.sub_category = action.payload.data
      state.is_loading = false
    })
    builder.addCase(update_subcategory_status.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(update_subcategory_status.rejected, (state, action) => {
      state.is_loading = false
    })
  },
})

export const { resetCategories } = actions
