import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"

import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import storage from "redux-persist/lib/storage"

import { rootReducer, RootState } from "../rootReducer"
import { setupListeners } from "@reduxjs/toolkit/dist/query"

export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  //   whitelist: ["auth"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      immutableCheck: false,
      //   serializableCheck: false,
    }),
  // .concat(applicationsApi.middleware),
})

setupListeners(store.dispatch)
