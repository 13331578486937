/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { OrderinitialState } from "./orderState"
import { authHeader } from "../../utils/headers"
import config from "../../utils/config"
import { toast } from "react-hot-toast"
import { store } from "../../app/store"
import { reset } from "../authentication/authenticationSlice"

let url = config.liveUrl

export const get_orders = createAsyncThunk(
  "order/get_orders",
  async (
    payload: {
      page: number
      selected: boolean | string
      isPaid: boolean | string
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    let link

    link = `${url}/orders?pageNumber=${payload?.page}&pageSize=10&status=${payload?.selected}&isPaid=${payload.isPaid}`

    try {
      const response = await axios.get(link, {
        headers: authHeader(token ? token : "123"),
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const get_order_details = createAsyncThunk(
  "order/get_order_details",
  async (
    payload: {
      id: string | undefined
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.get(`${url}/orders/${payload.id}`, {
        headers: authHeader(token ? token : "123"),
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const { reducer: OrderReducer, actions } = createSlice({
  name: "order",
  initialState: OrderinitialState,
  reducers: {
    resetOrders: (state) => {
      state.is_loading = false
      state.orders = null
      state.order = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(get_orders.fulfilled, (state, action) => {
      state.orders = action.payload.data
      state.is_loading = false
    })
    builder.addCase(get_orders.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(get_orders.rejected, (state, action) => {
      state.is_loading = false
    })
    builder.addCase(get_order_details.fulfilled, (state, action) => {
      state.order = action.payload
      state.is_loading = false
    })
    builder.addCase(get_order_details.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(get_order_details.rejected, (state, action) => {
      state.is_loading = false
    })
  },
})

export const { resetOrders } = actions
