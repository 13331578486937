import React, { useEffect } from "react"
import TextFlex from "../../components/TextFlex"
import { comma, myDate } from "../../utils/helper"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useParams } from "react-router-dom"
import { get_order_details, resetOrders } from "../../features/order/orderSlice"
import { FcApprove, FcCancel } from "react-icons/fc"
import { OrderItemsInterface } from "../../features/order/orderState"
import OrderFlex from "../../components/OrderFlex"
import Loader from "../../components/Preloader"

const DashboardOrderDetails = () => {
  const dispatch = useAppDispatch()
  const params = useParams()

  const { order, is_loading } = useAppSelector((state) => state.store_orders)

  const OrderHead = ["Image", "Name", "Quantity", "Unit Price"]

  useEffect(() => {
    dispatch(get_order_details({ id: params.id }))

    return () => {
      dispatch(resetOrders())
    }
  }, [dispatch, params.id])

  return (
    <div>
      {is_loading ? (
        <Loader />
      ) : order ? (
        <div>
          <h6> Customer's Details </h6>
          <hr />
          <div className="row">
            <OrderFlex
              title="Name"
              para={`${order?.user?.firstName} ${order?.user?.lastName}`}
            />
            <OrderFlex title="Email" para={order?.user?.email} />
            <OrderFlex title="Phone Number" para={order?.user?.phoneNumber} />
            <OrderFlex
              title="Address"
              para={`${order?.deliveryAddress?.address}, ${order?.deliveryAddress?.city}, ${order?.deliveryAddress?.country}`}
            />
          </div>

          <h6 className="mt-4"> Order Items </h6>
          <hr />
          <div className="mt-2 table-responsive shadow-sm dash_table">
            <table className="table table-bordered table-striped">
              <thead className="">
                <tr>
                  {OrderHead.map((cate, i) => (
                    <th key={i}>{cate}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {order?.orderItems?.map((l: OrderItemsInterface, i: number) => (
                  <tr key={i}>
                    <td>
                      <img
                        src={l.image}
                        alt="product"
                        style={{
                          width: "3rem",
                          height: "2rem",
                          objectFit: "contain",
                        }}
                      />
                    </td>
                    <td>{l.name}</td>
                    <td>{l.qty}</td>
                    <td> &#8358;{comma(String(l.price))} </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <h6 className="mt-4"> Payment Details </h6>
          <hr />
          <div className="row">
            <TextFlex title="Payment Method" para={`${order?.paymentMethod}`} />
            <TextFlex
              title="Payment Status"
              para={
                order?.isPaid ? (
                  <span>
                    Paid <FcApprove />
                  </span>
                ) : (
                  <span>
                    Not Paid <FcCancel />
                  </span>
                )
              }
            />
            <TextFlex
              title="Delivery Status"
              para={
                order?.isPaid ? (
                  <span>
                    Delivered <FcApprove />
                  </span>
                ) : (
                  <span>
                    Not Delivered <FcCancel />
                  </span>
                )
              }
            />
            <TextFlex title="Created At" para={myDate(order?.createdAt)} />
          </div>
          <div className="row mt-3">
            <div className="col-6">
              <p> Tax Price: </p>
            </div>
            <div className="col-6 text-end">
              <p> &#8358;{comma(String(order?.taxPrice))} </p>
            </div>

            <div className="col-6">
              <p> Delivery Price: </p>
            </div>
            <div className="col-6 text-end">
              <p> &#8358;{comma(String(order?.deliveryPrice))} </p>
            </div>

            <div className="col-6">
              <p> Items Price: </p>
            </div>
            <div className="col-6 text-end">
              <p> &#8358;{comma(String(order?.itemsPrice))} </p>
            </div>

            <hr />

            <div className="col-6">
              <strong> Total Price: </strong>
            </div>
            <div className="col-6 text-end">
              <strong> &#8358;{comma(String(order?.totalPrice))} </strong>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default DashboardOrderDetails
