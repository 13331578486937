/* eslint-disable prettier/prettier */
import React, { useEffect } from "react"
import {
  get_user_details,
  get_user_orders,
  resetUsers,
} from "../../features/user/userSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { Link, useParams } from "react-router-dom"
import Loader from "../../components/Preloader"
import OrderFlex from "../../components/OrderFlex"
import { myDate } from "../../utils/helper"
import { OrderInterface } from "../../features/order/orderState"

const DashboardUserDetails = () => {
  const dispatch = useAppDispatch()

  const params = useParams()

  const { user, is_loading, is_loading_orders, user_orders } = useAppSelector(
    (state) => state.store_users,
  )

  // console.log("user", user);
  

  const tableHead = ["S/N", "Name", "Total Price", "Date Created", "Action"]

  useEffect(() => {
    dispatch(get_user_details({ id: params.id }))
    dispatch(get_user_orders({ id: params.id }))

    return () => {
      dispatch(resetUsers())
    }
  }, [dispatch, params.id])

  return (
    <main className="user_dashboard">
      <div className="container">
        {is_loading ? (
          <Loader />
        ) : user ? (
          <div className="row">
            <OrderFlex
              title="Name"
              para={
                user?.type
                  ? `${user?.firstName} ${user?.lastName}`
                  : `${user?.businessName}`
              }
            />
            <OrderFlex title="Email" para={user?.email} />
            <OrderFlex title="Phone Number" para={user?.phoneNumber} />
            <OrderFlex title="Type" para={user?.type.toLowerCase()} />
          </div>
        ) : null}

        <h5 className="mt-5"> Recent Orders </h5>
        {is_loading_orders ? (
          <i className="fas fa-spinner fa-spin"></i>
        ) : user_orders && user_orders?.orders?.length > 0 ? (
          <div className="mt-2 table-responsive shadow-sm dash_table">
            <table className="table table-bordered table-striped">
              <thead className="">
                <tr>
                  {tableHead.map((cate, i) => (
                    <th key={i}>{cate}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {user_orders?.orders?.map((l: OrderInterface, i: number) => (
                  <tr key={l._id}>
                    <td>{++i}</td>
                    <td>
                      {l.orderItems.map((item, index) => (
                        <Link
                          key={index}
                          to={`/account/products/${item.product._id}`}
                        >
                          <span className="d-block"> {item.name} </span>
                        </Link>
                      ))}
                    </td>
                    <td>{l.totalPrice}</td>
                    <td>{myDate(l.createdAt)}</td>
                    <td>
                      <Link
                        to={`/account/orders/${l._id}`}
                        className="btn btn_site_dark text-white"
                      >
                        View Order
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <>No orders made by this user</>
        )}
      </div>
    </main>
  )
}

export default DashboardUserDetails
