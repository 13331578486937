/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

interface ImageItemProps {
  image: string;
  index: number;
  moveImage: (dragIndex: number, hoverIndex: number) => void;
}

const ImageItem: React.FC<ImageItemProps> = ({ image, index, moveImage }) => {
  const [, ref] = useDrop({
    accept: 'image',
    hover: (item: { index: number }) => {
      if (item.index !== index) {
        moveImage(item.index, index);
        item.index = index;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'image',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={(node) => drag(ref(node))}
      className="w-25 p-2"
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <img className="w-100 mb-2" src={image} alt="" />
    </div>
  );
};

interface ImageListProps {
  images: string[];
  onOrderChange: (newOrder: string[]) => void;
}

const ImageList: React.FC<ImageListProps> = ({ images, onOrderChange }) => {
  const [uploadedImages, setUploadedImages] = useState(images);

  const moveImage = (fromIndex: number, toIndex: number) => {
    const updatedImages = [...uploadedImages];
    const [movedImage] = updatedImages.splice(fromIndex, 1);
    updatedImages.splice(toIndex, 0, movedImage);
    setUploadedImages(updatedImages);
    onOrderChange(updatedImages);
  };

  return (
    <div className="d-flex flex-wrap">
      {uploadedImages.map((image, index) => (
        <ImageItem key={index} image={image} index={index} moveImage={moveImage} />
      ))}
    </div>
  );
};

export const DragDrop: React.FC<{ images: string[], onOrderChange: (newOrder: string[]) => void }> = ({ images, onOrderChange }) => (
  <DndProvider backend={HTML5Backend}>
    <ImageList images={images} onOrderChange={onOrderChange} />
  </DndProvider>
);
