import React, { useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import AuthLayout from "../../components/Auth/AuthLayout"
import { Field, Formik, FormikHelpers } from "formik"
import * as Yup from "yup"
import AuthHeaderText from "../../components/Auth/AuthHeaderText"
import { Link, useNavigate } from "react-router-dom"
import {
  login,
  resetAuth,
} from "../../features/authentication/authenticationSlice"

const Login = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { isSigningIn, admin } = useAppSelector((state) => state.auth)

  interface LoginFormValuesInterface {
    email: string
    password: string
  }

  const LoginFormSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("You need to enter a valid email"),
    password: Yup.string()
      .min(6, "length of characters must be more than 6.")
      .required("You need to enter password"),
  })

  const LoginForm = useRef(null)

  const loginPressed = (
    loginFormValues: LoginFormValuesInterface,
    { setSubmitting, setFieldError }: FormikHelpers<LoginFormValuesInterface>,
  ) => {
    dispatch(
      login({
        inputText: loginFormValues.email,
        password: loginFormValues.password,
      }),
    )
  }

  useEffect(() => {
    if (admin) {
      navigate("/account/dashboard")
    }
    return () => {
      dispatch(resetAuth())
    }
  }, [dispatch, admin])

  return (
    <main>
      <AuthLayout>
        <AuthHeaderText text="Log In To Your Account" />

        <div className="auth_form">
          <Formik
            onSubmit={loginPressed}
            innerRef={LoginForm}
            validationSchema={LoginFormSchema}
            initialValues={{
              email: "",
              password: "",
            }}
          >
            {({ handleSubmit, errors, touched }) => {
              return (
                <div>
                  <div className="auth_form_input">
                    <Field
                      className="form-control"
                      placeholder="Email address"
                      name="email"
                      type="text"
                    />
                    {errors.email && touched.email ? (
                      <p>{errors.email}</p>
                    ) : null}
                  </div>

                  <div className="auth_form_input">
                    <Field
                      className="form-control"
                      placeholder="Password"
                      type="password"
                      name="password"
                    />
                    {errors.password && touched.password ? (
                      <p>{errors.password}</p>
                    ) : null}
                  </div>

                  <div className="text-center auth_btn_div auth_btn_div2 mt-4">
                    <button
                      onClick={() => handleSubmit()}
                      className="btn site_btn"
                    >
                      {isSigningIn ? (
                        <i className="fas fa-spinner fa-spin"></i>
                      ) : (
                        <span> Log In</span>
                      )}
                    </button>
                    <div className="mt-3 existing">
                      <span>No account yet?</span>
                      <span className="text_gold">
                        <Link to="/signup"> Sign up here</Link>
                      </span>
                    </div>
                  </div>
                </div>
              )
            }}
          </Formik>
        </div>
      </AuthLayout>
    </main>
  )
}

export default Login
