import React, { useEffect } from "react"
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { GoHome, GoSignOut } from "react-icons/go"
// import { Link } from "react-router-dom";
import Admin from "../assets/avatar.png"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { reset } from "../features/authentication/authenticationSlice"
import config from "../utils/config"

const Header = (props: any) => {
  const dispatch = useAppDispatch()

  const logoutHandler = (e: any) => {
    e.preventDefault()
    dispatch(reset())
  }
  const { admin } = useAppSelector((state) => state.auth)

  return (
    <header className="">
      <div
        className={`container-fluid header pe-3 fixed_topp  ${props.openHalf}_pl`}
      >
        <button className="icons d-lg-none" onClick={props.openMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <p className="welcome">
          {props.title === "dashboard"
            ? `Your ${props.title?.replace("-", " ")}`
            : props.title?.replace("-", " ")}
        </p>
        <ul className="user">
          <UncontrolledDropdown nav className="">
            <DropdownToggle nav className="profile">
              {admin && (
                <>
                  <span className="user-name d-none d-lg-inline">
                    {admin.name}
                  </span>
                  {<img src={Admin} alt="admin" className="prof_img" />}
                </>
              )}
            </DropdownToggle>
            <DropdownMenu end className="profile-user">
              <ul className="pro-body">
                <li>
                  <a href="/" className="dropdown-item">
                    <GoHome />
                    Home
                  </a>
                </li>
                <li>
                  <a href="#" className="dropdown-item" onClick={logoutHandler}>
                    <GoSignOut /> Log Out
                  </a>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </ul>
      </div>
    </header>
  )
}

export default Header
