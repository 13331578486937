/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import ModalComponent from "../../components/ModalComponent"
import Loader from "../../components/Preloader"
import { GrFormView } from "react-icons/gr"
import {
  get_users,
  resetUsers,
  create_vendor,
  update_user_status,
  get_users_no_pag,
} from "../../features/user/userSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { UserInterface } from "../../features/user/userState"
import Pagination from "../../components/Pagination"
import { CSVLink } from "react-csv"

const DashboardUser = () => {
  const dispatch = useAppDispatch()

  const { user, users, is_loading, users_no_pag } = useAppSelector(
    (state) => state.store_users,
  )

  const [openModal, setOpenModal] = useState(false)
  const [message, setMessage] = useState<string | null>(null)
  const [businessName, setBusinessName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [password, setPassword] = useState("123456")
  const [type, setType] = useState("VENDOR")
  const [selected, setSelected] = useState<string>("true")
  const [keyword, setKeyword] = useState("")

  const createBrandToggler = (e: any) => {
    e.preventDefault()
    setOpenModal(true)
    setBusinessName("")
  }

  const toggleModal = () => {
    setOpenModal(!openModal)
  }

  const searchHandler = (e: any) => {
    e.preventDefault()
    dispatch(
      get_users({
        page: pageNumber,
        keyword,
        selected: selected === "true" ? true : false,
      }),
    )
  }

  const statusUpdateHandler = (e: any, id: number) => {
    e.preventDefault()
    if (window.confirm("Are you sure?")) {
      dispatch(update_user_status({ id }))

      setTimeout(() => {
        setOpenModal(false)
      }, 1000)
    }
  }

  const submitHandler = (e: any) => {
    e.preventDefault()
    if (
      businessName !== "" &&
      email !== "" &&
      phoneNumber !== "" &&
      password !== "" &&
      type !== ""
    ) {
      let data = {
        businessName,
        email,
        phoneNumber,
        password: "123456",
        type,
      }
      dispatch(create_vendor(data))
      setTimeout(() => {
        setBusinessName("")
        setEmail("")
        setPhoneNumber("")
        setPassword("123456")
        setType("VENDOR")
        setOpenModal(false)
      }, 1000)
    } else {
      setMessage("Please fill in all details")
    }
  }

  const brandHead = ["S/N", "Name", "Status", "Edit"]

  const pageNumber =
    Number(new URLSearchParams(useLocation().search).get("page")) || 1

  const headers = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Business Name", key: "businessName" },
    { label: "Phone Number", key: "phoneNumber" },
    { label: "Email", key: "email" },
    { label: "Type", key: "type" },
    { label: "Created At", key: "createdAt" },
  ]

  // console.log(users_no_pag?.users);
  

  useEffect(() => {
    dispatch(
      get_users({
        page: pageNumber,
        keyword,
        selected: selected === "true" ? true : false,
      }),
    )
    dispatch(get_users_no_pag())

    return () => {
      dispatch(resetUsers())
    }
  }, [dispatch, user, pageNumber, selected, keyword])

  return (
    <section className="user_dashboard">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <Link
              to="#"
              onClick={(e) => {
                createBrandToggler(e)
              }}
              className="btn btn_site_dark me-3"
            >
              Create User
            </Link>

            {users_no_pag && (
              <CSVLink
                data={users_no_pag?.users}
                headers={headers}
                className="btn btn_site_dark text-white"
                filename="all-users.csv"
              >
                Export CSV
              </CSVLink>
            )}
          </div>

          <div className="col-12 mt-3">
            <div className="row">
              <div className="col-md-6 mb-2">
                <input
                  type="input"
                  placeholder="search"
                  className="form-control"
                  onChange={(e) => setKeyword(e.target.value)}
                  onInput={searchHandler}
                  style={{ fontSize: "12px" }}
                />
              </div>

              <div className="col md-6">
                <select
                  value={selected}
                  onChange={(e) => setSelected(e.target.value)}
                  className="form-select"
                  style={{ fontSize: "12px" }}
                >
                  <option value="" disabled>Status</option>
                  {/* <option value=''>All</option> */}
                  <option value={"true"}>Active</option>
                  <option value={"false"}>Inactive</option>
                </select>
              </div>
            </div>
          </div>

          <div className="col-12">
            {is_loading ? (
              <Loader />
            ) : (
              <>
                {users_no_pag && users_no_pag?.users?.length > 0 ? (
                  <div className="mt-5 table-responsive shadow-sm dash_table">
                    <table className="table table-bordered table-striped">
                      <thead className="">
                        <tr>
                          {brandHead.map((cate, i) => (
                            <th key={i}>{cate}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {users_no_pag?.users?.map((l: UserInterface, i: number) => (
                          <tr key={l._id}>
                            <td>{i+ 1}</td>
                            <td>
                              <Link to={`/account/users/${l._id}`}>
                                {l.type 
                                  ? `${l.firstName} ${l.lastName}`
                                  : l.businessName}
                                <br /> 
                              </Link>
                            </td>
                            <td>
                              {l.active === true ? (
                                <span className="status active_status">
                                  Active
                                </span>
                              ) : (
                                <span className="status inactive_status">
                                  Inactive
                                </span>
                              )}
                            </td>

                            <td>
                              <button
                                className="btn btn_site"
                                onClick={(e) => statusUpdateHandler(e, l._id)}
                                disabled={is_loading ? true : false}
                              >
                               {l.active ? "Suspend User" : "Activate User"}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/* {users_no_pag?.users.length > 1 && (
                      <>
                        <Pagination
                          currentPage={Number(users_no_pag?.users.length)}
                          totalCount={Number(users_no_pag?.users.length)}
                          pageSize={10}
                          pathname={"/account/users/"}
                        />
                      </>
                    )} */}
                  </div>
                ) : (
                  <div className="card mt-3">
                    <div className="card-body">
                      <p className="nothing">No Users found</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <ModalComponent
        open={openModal}
        toggle={toggleModal}
        size="lg"
        title={`Add User`}
      >
        <div className="login_form">
          <form className="tagForm" onSubmit={submitHandler}>
            <label className="label">Name</label>
            <input
              type="text"
              value={businessName}
              className="form-control mb-3"
              onChange={(e) => setBusinessName(e.target.value)}
            />

            <label className="label">Email</label>
            <input
              type="email"
              value={email}
              className="form-control mb-3"
              onChange={(e) => setEmail(e.target.value)}
            />

            <label className="label">Phone Number</label>
            <input
              type="tel"
              value={phoneNumber}
              className="form-control mb-3"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />

            <label className="label">Type</label>
            <select
              value={phoneNumber}
              className="form-select mb-3"
              onChange={(e) => setType(e.target.value)}
            >
              <option value="VENDOR">Vendor</option>
              <option value="CUSTOMER">Customer</option>
            </select>

            <div className="d-flex justify-content-between mt-4">
              <div>
                <button disabled={is_loading} className="btn btn_site_dark">
                  {is_loading ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : (
                    "Create"
                  )}
                </button>
                <p style={{ fontSize: "12px", color: "red" }}> {message} </p>
              </div>
            </div>
          </form>
        </div>
      </ModalComponent>
    </section>
  )
}

export default DashboardUser
