/* eslint-disable prettier/prettier */
import React from "react";
import { MdDashboard, MdSearch, MdBook } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { AiOutlineBook } from "react-icons/ai";
import { RiFileList2Fill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { MenuInterface } from "./menu";

const NavLinks = ({
    link,
    openHalf,
    // toggleOpenHalf,
    handleToggle,
}: {
    link: MenuInterface;
    openHalf: boolean;
    // toggleOpenHalf: (e: any) => void
    handleToggle: (e: any) => void;
}) => {
    const toggleHandler = (e: any) => {
        e.preventDefault();
        // toggleOpenHalf(e)
        handleToggle(e);
    };

    const navHandler = () => {
        window.location.href = "/";
    };

    return (
        <li className="side-list" onClick={(e) => toggleHandler(e)}>
            {/* {link.name === "Search" ? (
                <a
                    href={`${link.href}`}
                    onClick={navHandler}
                    className={`side-link`}
                >
                    <MdSearch size={openHalf ? 20 : 18} />

                    <span>{link.name}</span>
                </a>
            ) : ( */}
            <NavLink to={link.href} className={`side-link`}>
                {link.icon === "dash" ? (
                    <MdDashboard size={openHalf ? 20 : 18} />
                ) : link.icon === "profile" ? (
                    <FaUserCircle size={openHalf ? 20 : 18} />
                ) : link.icon === "search" ? (
                    <MdSearch size={openHalf ? 20 : 18} />
                ) : link.icon === "bookings" ? (
                    <MdBook size={openHalf ? 20 : 18} />
                ) : link.icon === "reports" ? (
                    <RiFileList2Fill size={openHalf ? 20 : 18} />
                ) : (
                    <AiOutlineBook size={openHalf ? 20 : 18} />
                )}
                <span>{link.name}</span>
            </NavLink>
            {/* )} */}
        </li>
    );
};

export default NavLinks;
