/* eslint-disable prettier/prettier */
import { OrderInterface } from "../order/orderState"
import { MetaInterface } from "../product/productState"

export interface UserInitialInterface {
  is_loading: boolean
  is_loading_orders: boolean
  users: UsersInterface | null
  users_no_pag: UsersInterface | null
  user: UserInterface | null
  user_orders: UserOrdersInterface | null
}

export const UserInitialState: UserInitialInterface = {
  is_loading: false,
  is_loading_orders: false,
  user: null,
  user_orders: null,
  users: null,
  users_no_pag: null,
}

export interface UsersInterface {
  length: number
  users: UserInterface[]
  meta: MetaInterface
}

export interface UserInterface {
  _id: number
  active: boolean
  email: string
  businessName: string
  firstName: string
  lastName: string
  phoneNumber: string
  type: string
}

export interface UserOrdersInterface {
  orders: OrderInterface[]
  meta: MetaInterface
}
