export interface AuthenticationState {
  token: string | null
  isSigningIn: boolean
  admin: Admin | null
  signup_admin: Admin | null
}

export const AuthenticationInitialState: AuthenticationState = {
  token: null,
  isSigningIn: false,
  admin: null,
  signup_admin: null,
}

export interface Admin {
  id: number
  active: boolean
  email: string
  name: string
  isSuperAdmin: boolean
  isAdmin: boolean
}
