import React from "react"
import {
  FcBusinessman,
  FcCellPhone,
  FcHome,
  FcInvite,
  FcReadingEbook,
} from "react-icons/fc"

const OrderFlex = ({
  title,
  para,
}: {
  title?: string
  para: string | undefined
}) => {
  return (
    <div className="col-md-6 col-sm-6 mb-3">
      <div
        className="d-flex gap-3 justify-content-center"
        style={{
          border: "1px solid #ccc",
          borderRadius: "1rem",
          alignItems: "center",
        }}
      >
        <h6 className="mb-0">
          {title === "Name" ? (
            <FcBusinessman />
          ) : title === "Email" ? (
            <FcInvite />
          ) : title === "Phone Number" ? (
            <FcCellPhone />
          ) : title === "Type" ? (
            <FcReadingEbook />
          ) : (
            <FcHome />
          )}
        </h6>
        <p className="mb-0"> {para} </p>
      </div>
    </div>
  )
}

export default OrderFlex
