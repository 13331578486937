import React, { ReactNode } from "react"
import login_bg from "../../assets/bg_images/auth_bg.jpg"
import travel_logo from "../../assets/brand.png"
// import "../../styles/auth.css"
import { Link } from "react-router-dom"

const AuthLayout = ({ children }: { children: ReactNode }) => {
  return (
    <main className="container-fluid auth">
      <div className="row">
        <div className="col-md-6 auth_bg p-0">
          <img src={login_bg} alt="" />
        </div>
        <div className="col-md-6 auth_bg2">
          <div className="row justify-content-center">
            <div className="col-md-10 col-11">
              <div className="auth_bg2_div">
                <div className="auth_logo_div">
                  <Link to="/">
                    <img src={travel_logo} alt="travelden logo" className="" />
                  </Link>
                </div>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default AuthLayout
