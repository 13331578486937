/* eslint-disable prettier/prettier */
import { combineReducers } from "redux"
import { AuthenticationReducer } from "./features/authentication/authenticationSlice"
import { BrandReducer } from "./features/brand/brandSlice"
import { CategoryReducer } from "./features/category/categorySlice"
import { ProductReducer } from "./features/product/productSlice"
import { UserReducer } from "./features/user/userSlice"
import { OrderReducer } from "./features/order/orderSlice"

export const rootReducer = combineReducers({
  auth: AuthenticationReducer,
  store_brands: BrandReducer,
  store_categories: CategoryReducer,
  store_products: ProductReducer,
  store_users: UserReducer,
  store_orders: OrderReducer,
})

// export default rootReducer
export type RootState = ReturnType<typeof rootReducer>
