/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { ProductInitialState } from "./productState"
import { authHeader } from "../../utils/headers"
import config from "../../utils/config"
import { toast } from "react-hot-toast"
import { store } from "../../app/store"
import { reset } from "../authentication/authenticationSlice"

let url = config.liveUrl

export const post_product = createAsyncThunk(
  "product/post_product",
  async (
    payload: {
      name: string
      price: string
      old_price: string
      brand_name: string
      category: string
      gender: string
      new_product: boolean
      quantity: string
      description: string
      sizes: string[]
      colors: string[]
      image: string[]
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.post(`${url}/products`, payload, {
        headers: authHeader(token ? token : "123"),
      })
      toast.success(response.data.message, {
        position: "top-right",
      })

      return response.data
      
    } catch (error: any) {
      
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const update_product = createAsyncThunk(
  "product/update_product",
  async (
    payload: {
      name: string
      id: string
      price: string
      old_price: string
      brand_name: string
      category: string
      gender: string
      new_product: boolean
      quantity: string
      description: string
      sizes: string[]
      colors: string[]
      image: string[]
      slug: string
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.put(
        `${url}/products/${payload.id.toLowerCase().replace(" ", "-")}`,
        payload,
        {
          headers: authHeader(token ? token : "123"),
        },
      )
      // console.log("upload payload", payload)
      toast.success(response.data.message, {
        position: "top-right",
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const get_products_no_pag = createAsyncThunk(
  "product/get_products_no_pag",
  async (_, thunkAPI) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.get(`${url}/products/no-pag`, {
        headers: authHeader(token ? token : "123"),
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const get_products = createAsyncThunk(
  "product/get_products",
  async (
    payload: {
      // page: number
      keyword: string
      selected: boolean
      // vendorId: string
      brandId: string
      categoryId: string
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    let link

    // link = `${url}/products?pageNumber=${payload?.page}&pageSize=10&status=${payload?.selected}&categoryId=${payload.categoryId}&brandId=${payload?.brandId}&vendorId=${payload?.vendorId}`
    link = `${url}/products/available`

    // if (payload?.keyword !== "")
    //   link = `${url}/products?pageNumber=${payload?.page}&pageSize=10&status=${payload?.selected}&categoryId=${payload?.categoryId}&brandId=${payload?.brandId}&vendorId=${payload?.vendorId}&keyword=${payload?.keyword}`

    try {
      const response = await axios.get(link, {
        headers: authHeader(token ? token : "123"),
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const delete_product = createAsyncThunk(
  "product/delete_product",
  async (
    payload: {
      id: number
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.delete(
        `${url}/products/${payload.id}/delete`,
        {
          headers: authHeader(token ? token : "123"),
        },
      )

      toast.success(response.data.message, {
        position: "top-right",
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const update_product_status = createAsyncThunk(
  "product/update_product_status",
  async (
    payload: {
      slug: string
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.put(
        `${url}/products/status/${payload.slug}`,
        payload,
        {
          headers: authHeader(token ? token : "123"),
        },
      )

      toast.success(response.data.message, {
        position: "top-right",
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const get_product_reviews = createAsyncThunk(
  "product/get_product_reviews",
  async (
    payload: {
      id: string | undefined
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.get(`${url}/reviews/${payload.id}`, {
        headers: authHeader(token ? token : "123"),
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const delete_product_review = createAsyncThunk(
  "product/delete_product_review",
  async (
    payload: {
      id: string
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.delete(`${url}/reviews/${payload.id}`, {
        headers: authHeader(token ? token : "123"),
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const get_all_reviews = createAsyncThunk(
  "product/get_all_reviews",
  async (_, thunkAPI) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.get(`${url}/reviews`, {
        headers: authHeader(token ? token : "123"),
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const get_product_details = createAsyncThunk(
  "product/get_product_details",
  async (
    payload: {
      id: string | undefined
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.get(`${url}/products/${payload.id}`, {
        headers: authHeader(token ? token : "123"),
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const get_favourites = createAsyncThunk(
  "product/get_favourites",
  async (
    payload: {
      page: number
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.get(
        `${url}/favourites?pageNumber=${payload?.page}&pageSize=10`,
        {
          headers: authHeader(token ? token : "123"),
        },
      )

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const get_favourites_by_type = createAsyncThunk(
  "product/get_favourites_by_type",
  async (
    payload: {
      page: string
      id: string
      type: string
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    let link
    if (payload.type === "user")
      link = `${url}/favourites/users/${payload.id}?pageNumber=${payload?.page}&pageSize=10`
    else
      link = `${url}/favourites/products/${payload.id}?pageNumber=${payload?.page}&pageSize=10`

    try {
      const response = await axios.get(link, {
        headers: authHeader(token ? token : "123"),
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const { reducer: ProductReducer, actions } = createSlice({
  name: "product",
  initialState: ProductInitialState,
  reducers: {
    resetProducts: (state) => {
      state.is_loading = false
      state.products = null
      state.product = null
      state.favourites = null
      state.products_no_pag = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(post_product.fulfilled, (state, action) => {
      state.product = action.payload.data
      state.is_loading = false
    })
    builder.addCase(post_product.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(post_product.rejected, (state, action) => {
      state.is_loading = false
    })
    builder.addCase(update_product.fulfilled, (state, action) => {
      state.product = action.payload.data
      state.is_loading = false
    })
    builder.addCase(update_product.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(update_product.rejected, (state, action) => {
      state.is_loading = false
    })
    builder.addCase(get_products.fulfilled, (state, action) => {
      state.products = action.payload.data
      state.is_loading = false
    })
    builder.addCase(get_products.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(get_products.rejected, (state, action) => {
      state.is_loading = false
    })
    builder.addCase(delete_product.fulfilled, (state, action) => {
      state.del_product = action.payload
      state.is_loading = false
    })
    builder.addCase(delete_product.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(delete_product.rejected, (state, action) => {
      state.is_loading = false
    })
    builder.addCase(update_product_status.fulfilled, (state, action) => {
      state.product = action.payload.data
      state.is_loading = false
    })
    builder.addCase(update_product_status.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(update_product_status.rejected, (state, action) => {
      state.is_loading = false
    })
    builder.addCase(get_product_reviews.fulfilled, (state, action) => {
      state.product_review = action.payload.data
      state.is_loading_review = false
    })
    builder.addCase(get_product_reviews.pending, (state, action) => {
      state.is_loading_review = true
    })
    builder.addCase(get_product_reviews.rejected, (state, action) => {
      state.is_loading_review = false
    })
    builder.addCase(delete_product_review.fulfilled, (state, action) => {
      state.delete_review = action.payload.data
      state.is_loading_review = false
    })
    builder.addCase(delete_product_review.pending, (state, action) => {
      state.is_loading_review = true
    })
    builder.addCase(delete_product_review.rejected, (state, action) => {
      state.is_loading_review = false
    })
    builder.addCase(get_all_reviews.fulfilled, (state, action) => {
      state.all_reviews = action.payload.data
      state.is_loading = false
    })
    builder.addCase(get_all_reviews.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(get_all_reviews.rejected, (state, action) => {
      state.is_loading = false
    })
    builder.addCase(get_product_details.fulfilled, (state, action) => {
      state.product = action.payload.data
      state.is_loading = false
    })
    builder.addCase(get_product_details.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(get_product_details.rejected, (state, action) => {
      state.is_loading = false
    })
    builder.addCase(get_favourites.fulfilled, (state, action) => {
      state.favourites = action.payload.data
      state.is_loading = false
    })
    builder.addCase(get_favourites.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(get_favourites.rejected, (state, action) => {
      state.is_loading = false
    })
    builder.addCase(get_favourites_by_type.fulfilled, (state, action) => {
      state.favourites = action.payload.data
      state.is_loading = false
    })
    builder.addCase(get_favourites_by_type.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(get_favourites_by_type.rejected, (state, action) => {
      state.is_loading = false
    })
    builder.addCase(get_products_no_pag.fulfilled, (state, action) => {
      state.products_no_pag = action.payload.data
      state.is_loading = false
    })
    builder.addCase(get_products_no_pag.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(get_products_no_pag.rejected, (state, action) => {
      state.is_loading = false
    })
  },
})

export const { resetProducts } = actions
