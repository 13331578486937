import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FiUsers } from "react-icons/fi";
import { AiOutlineBook } from "react-icons/ai";
import { CgMoreO } from "react-icons/cg";
import { BsChevronDown, BsChevronUp, BsDot } from "react-icons/bs";
import { ImLocation2 } from "react-icons/im";
import { MenuInterface } from "./menu";

const NavCollapse = ({
    link,
    openHalf,
    // toggleOpenHalf,
    handleToggle,
}: {
    link: MenuInterface;
    openHalf: boolean;
    // toggleOpenHalf: (e: any) => void;
    handleToggle: (e: any) => void;
}) => {
    const [open, setOpen] = useState(false);

    const location = useLocation();

    const toggleDropdown = (e: any) => {
        e.preventDefault();
        setOpen(!open);
    };

    const toggleHandler = (e: any) => {
        e.preventDefault();
        // toggleOpenHalf(e);
        handleToggle(e);
    };

    useEffect(() => {
        if (location.pathname.split("/")[2] === link.name) {
            setOpen(true);
        }
    }, [open]);

    return (
        <li className="side-list">
            <a
                className="side-link drop"
                href={link.href}
                onClick={toggleDropdown}
            >
                {link.icon === "more-services" ? (
                    <CgMoreO size={openHalf ? 20 : 18} />
                ) : link.icon === "user" ? (
                    <FiUsers />
                ) : link.icon === "location" ? (
                    <ImLocation2 />
                ) : (
                    <AiOutlineBook />
                )}
                <span
                    className="d-flex justify-content-between align-items-center"
                    style={{ width: "100%" }}
                >
                    {link.name}
                    {open ? <BsChevronDown /> : <BsChevronUp />}
                </span>
            </a>
            {open && (
                <ul className="side-dropdown">
                    {link.children.map((l: any) => (
                        <li key={l.id} className="side-list">
                            <NavLink to={l.href} className={`side-link`}>
                                <BsDot /> {l.name}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            )}
        </li>
    );
};

export default NavCollapse;
