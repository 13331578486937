import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { AuthenticationInitialState } from "./authenticationState"
import { authHeader, header } from "../../utils/headers"
import config from "../../utils/config"
import { toast } from "react-hot-toast"
import { store } from "../../app/store"

let url = config.liveUrl

export const admin_signup = createAsyncThunk(
  "authentication/admin_signup",
  async (
    payload: {
      name: string
      email: string
      password: string
    },
    thunkAPI,
  ) => {
    const { rejectWithValue } = thunkAPI

    try {
      const response = await axios.post(`${url}/admin`, payload, {
        headers: header,
      })

      toast.success(response.data.message, {
        position: "top-right",
      })

      return response.data
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      toast.error(message, { position: "top-right" })
      if (!error.response) {
        throw error
      }
      return rejectWithValue(message)
    }
  },
)

export const login = createAsyncThunk(
  "authentication/login",
  async (
    payload: {
      inputText: string
      password: string
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI

    try {
      const response = await axios.post(`${url}/admin/login`, payload, {
        headers: header,
      })
      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }

      toast.error(message, { position: "top-right" })

      return message
    }
  },
)

export const { reducer: AuthenticationReducer, actions } = createSlice({
  name: "authentication",
  initialState: AuthenticationInitialState,
  reducers: {
    reset: (state) => {
      state.isSigningIn = false
      state.token = null
      state.admin = null
      state.signup_admin = null
    },
    resetAuth: (state) => {
      state.signup_admin = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(admin_signup.fulfilled, (state, action) => {
      state.signup_admin = action.payload.data
      state.isSigningIn = false
    })
    builder.addCase(admin_signup.pending, (state, action) => {
      state.isSigningIn = true
    })
    builder.addCase(admin_signup.rejected, (state, action) => {
      state.isSigningIn = false
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.token = action.payload?.data?.token
      state.admin = action?.payload?.data
      state.isSigningIn = false
    })
    builder.addCase(login.pending, (state, action) => {
      state.isSigningIn = true
    })
    builder.addCase(login.rejected, (state, action) => {
      state.isSigningIn = false
    })
  },
})

export const { reset, resetAuth } = actions
