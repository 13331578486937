/* eslint-disable prettier/prettier */
import { BrandsInterface } from "../brand/brandState"

export interface CategoryInitialInterface {
  categories: CategoryInterface[] | null
  category: CategoryInterface | null
  sub_each_subCategory: CategoryInterface | null
  sub_category: SubCategoryInterface[] | null
  is_loading: boolean
  is_fetching: boolean
}

export const CategoryInitialState: CategoryInitialInterface = {
  categories: null,
  category: null,
  sub_each_subCategory: null,
  sub_category: [],
  is_loading: false,
  is_fetching: false,
}

export interface CategoryInterface extends BrandsInterface {
  description: string
  parentCategory: string
  subcategories: string
}
export interface SubCategoryInterface {
  _id: string
  status: boolean
  gender: string
  slug: string
  name: string
  description: string
  // category: any
}
