/* eslint-disable prettier/prettier */
import React, { Suspense } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Toaster } from "react-hot-toast"
import Preloader from "./components/Preloader"

import Signup from "./pages/auth/Signup"
import AuthPath from "./AuthPath"
import Login from "./pages/auth/Login"
import AdminPath from "./AdminPath"
import Dashboard from "./pages/dashboard/Dashboard"

import "./styles/styles.scss"
import "./index.css"
import DashboardProfile from "./pages/dashboard/DashboardProfile"
import DashboardBrand from "./pages/dashboard/DashboardBrand"
import DashboardCategory from "./pages/dashboard/DashboardCategory"
import DashboardProduct from "./pages/dashboard/DashboardProduct"
import DashboardOrders from "./pages/dashboard/DashboardOrders"
import DashboardUser from "./pages/dashboard/DashboardUser"
import DashboardProductDetails from "./pages/dashboard/DashboardProductDetails"
import DashboardUserDetails from "./pages/dashboard/DashboardUserDetails"
import DashboardFavourites from "./pages/dashboard/DashboardFavourites"
import DashboardReview from "./pages/dashboard/DashboardReview"
import DashboardOrderDetails from "./pages/dashboard/DashboardOrderDetails"
import DashboardSubCategory from "./pages/dashboard/DashboardSubCategory"
import DashboardColors from "./pages/dashboard/DashboardColors"
import DashboardSizes from "./pages/dashboard/DashboardSizes"

function App() {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<Preloader />}>
          <Routes>
            {/* ------------------------------ auth routes  ---------------------------------- */}
            <Route path="/" element={<AuthPath />}>
              <Route path="signup" element={<Signup />} />
              <Route path="" element={<Login />} />
            </Route>

            {/* ------------------------------ dashboard routes  ---------------------------------- */}
            <Route path="/account/" element={<AdminPath />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="profile" element={<DashboardProfile />} />
              <Route path="favourites" element={<DashboardFavourites />} />
              <Route path="reviews" element={<DashboardReview />} />
              <Route path="sizes" element={<DashboardSizes />} />
              <Route path="colors" element={<DashboardColors />} />
              <Route path="brands" element={<DashboardBrand />} />
              <Route path="categories" element={<DashboardCategory />} />
              <Route path="categories/:id" element={<DashboardSubCategory />} />
              <Route path="products" element={<DashboardProduct />} />
              <Route
                path="products/:id"
                element={<DashboardProductDetails />}
              />
              <Route path="orders" element={<DashboardOrders />} />
              <Route path="orders/:id" element={<DashboardOrderDetails />} />
              <Route path="users" element={<DashboardUser />} />
              <Route path="users/:id" element={<DashboardUserDetails />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
      <Toaster />
    </>
  )
}

export default App
