/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { BrandInitialState } from "./brandState"
import { authHeader, header } from "../../utils/headers"
import config from "../../utils/config"
import { toast } from "react-hot-toast"
import { store } from "../../app/store"
import { reset } from "../authentication/authenticationSlice"

let url = config.liveUrl

export const post_brand = createAsyncThunk(
  "brand/post_brand",
  async (
    payload: {
      name: string
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.post(`${url}/brands`, payload, {
        headers: authHeader(token ? token : "123"),
      })

      toast.success(response.data.message, {
        position: "top-right",
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const update_brand = createAsyncThunk(
  "brand/update_brand",
  async (
    payload: {
      name: string
      slug: string
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.put(
        `${url}/brands/${payload.slug.toLowerCase().replace(" ", "-")}`,
        payload,
        {
          headers: authHeader(token ? token : "123"),
        },
      )

      toast.success(response.data.message, {
        position: "top-right",
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const get_brands = createAsyncThunk(
  "brand/get_brands",
  async (_, thunkAPI) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.get(`${url}/brands`, {
        headers: authHeader(token ? token : "123"),
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const delete_brand = createAsyncThunk(
  "brand/delete_brand",
  async (
    payload: {
      id: string
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.delete(
        `${url}/brands/${payload.id}`,
        {
          headers: authHeader(token ? token : "123"),
        },
      )
      // console.log("id", payload.id);
      
      toast.success(response.data.message, {
        position: "top-right",
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const update_brand_status = createAsyncThunk(
  "brand/update_brand_status",
  async (
    payload: {
      slug: string
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.put(
        `${url}/brands/status/${payload.slug}`,
        payload,
        {
          headers: authHeader(token ? token : "123"),
        },
      )

      toast.success(response.data.message, {
        position: "top-right",
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const get_category_brand = createAsyncThunk(
  "brand/get_category_brand",
  async (
    payload: {
      id: any
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI

    try {
      const response = await axios.get(`${url}/brands/category/${payload.id}`, {
        headers: header,
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const { reducer: BrandReducer, actions } = createSlice({
  name: "brand",
  initialState: BrandInitialState,
  reducers: {
    resetBrands: (state) => {
      state.is_loading = false
      state.brands = null
      state.brand = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(post_brand.fulfilled, (state, action) => {
      state.brand = action.payload.data
      state.is_loading = false
    })
    builder.addCase(post_brand.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(post_brand.rejected, (state, action) => {
      state.is_loading = false
    })
    builder.addCase(update_brand.fulfilled, (state, action) => {
      state.brand = action.payload.data
      state.is_loading = false
    })
    builder.addCase(update_brand.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(update_brand.rejected, (state, action) => {
      state.is_loading = false
    })
    builder.addCase(get_brands.fulfilled, (state, action) => {
      state.brands = action.payload.data
      state.is_fetching = false
    })
    builder.addCase(get_brands.pending, (state, action) => {
      state.is_fetching = true
    })
    builder.addCase(get_brands.rejected, (state, action) => {
      state.is_fetching = false
    })
    builder.addCase(delete_brand.fulfilled, (state, action) => {
      state.brand = action.payload.data
      state.is_fetching = false
    })
    builder.addCase(delete_brand.pending, (state, action) => {
      state.is_fetching = true
    })
    builder.addCase(delete_brand.rejected, (state, action) => {
      state.is_fetching = false
    })
    builder.addCase(update_brand_status.fulfilled, (state, action) => {
      state.brand = action.payload.data
      state.is_loading = false
    })
    builder.addCase(update_brand_status.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(update_brand_status.rejected, (state, action) => {
      state.is_loading = false
    })
    builder.addCase(get_category_brand.fulfilled, (state, action) => {
      state.cat_brands = action.payload.data
      state.is_loading = false
    })
    builder.addCase(get_category_brand.pending, (state, action) => {
      state.is_loading = true
    })
    builder.addCase(get_category_brand.rejected, (state, action) => {
      state.is_loading = false
    })
  },
})

export const { resetBrands } = actions
