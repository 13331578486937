import React, { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  get_favourites,
  resetProducts,
} from "../../features/product/productSlice"
import Loader from "../../components/Preloader"
import { Link, useLocation } from "react-router-dom"
import Pagination from "../../components/Pagination"
import { FavouritesInfoInterface } from "../../features/product/productState"

const DashboardFavourites = () => {
  const dispatch = useAppDispatch()

  const pageNumber =
    Number(new URLSearchParams(useLocation().search).get("page")) || 1

  const { favourites, is_loading } = useAppSelector(
    (state) => state.store_products,
  )

  const brandHead = ["S/N", "Image", "Product", "Customer"]

  useEffect(() => {
    dispatch(
      get_favourites({
        page: pageNumber,
      }),
    )

    return () => {
      dispatch(resetProducts())
    }
  }, [dispatch, pageNumber])

  return (
    <section className="user_dashboard">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {is_loading ? (
              <Loader />
            ) : (
              <>
                {favourites &&
                favourites?.favourites &&
                favourites?.favourites?.length > 0 ? (
                  <div className="mt-5 table-responsive shadow-sm dash_table">
                    <table className="table table-bordered table-striped text-center">
                      <thead className="">
                        <tr>
                          {brandHead.map((cate, i) => (
                            <th key={i}>{cate}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {favourites.favourites.map(
                          (l: FavouritesInfoInterface, i: number) => (
                            <tr key={l._id}>
                              <td>{(favourites?.meta.page - 1) * 3 + i + 1}</td>
                              <td>
                                <img
                                  src={l.product.image[0]}
                                  alt=""
                                  style={{
                                    height: "2rem",
                                    width: "4rem",
                                    objectFit: "cover",
                                  }}
                                />
                              </td>
                              <td>
                                <Link to={`/account/products/${l.product._id}`}>
                                  <span className="d-block">
                                    {l.product.name}
                                  </span>
                                </Link>
                              </td>
                              <td>
                                <Link to={`/account/users/${l?.user?._id}`}>
                                  {l.user?.type === "CUSTOMER"
                                    ? `${l.user?.firstName} ${l.user?.lastName}`
                                    : `${l.user?.businessName}`}{" "}
                                </Link>
                              </td>
                            </tr>
                          ),
                        )}
                      </tbody>
                    </table>

                    {favourites && favourites?.meta.pages > 1 && (
                      <>
                        <Pagination
                          currentPage={Number(favourites.meta.page)}
                          totalCount={Number(favourites.meta.total)}
                          pageSize={10}
                          pathname={"/account/favourites/"}
                        />
                      </>
                    )}
                  </div>
                ) : (
                  <div className="card mt-3">
                    <div className="card-body">
                      <p className="nothing">No Products found</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default DashboardFavourites
