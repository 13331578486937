import React from "react"
import Logo from "../assets/loader_ball.gif"

const Preloader = () => {
  return (
    <div className="preloader_active">
      <img
        className="logo"
        alt="Logo"
        src={Logo}
        width="100px"
        height="100px"
      />
    </div>
  )
}

export default Preloader
