/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import ModalComponent from "../../components/ModalComponent"
import Loader from "../../components/Preloader"
import { GrFormEdit, GrFormView, GrTrash } from "react-icons/gr"
import {
  get_categories,
  post_category,
  resetCategories,
  update_category,
  update_category_status,
  post_subcategories,
  delete_category,
} from "../../features/category/categorySlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { CategoryInterface } from "../../features/category/categoryState"

const DashboardCategory = () => {
  const dispatch = useAppDispatch()

  const { category, categories, is_loading, is_fetching } = useAppSelector(
    (state) => state.store_categories,
  )

  const [openModal, setOpenModal] = useState(false)
  const [message, setMessage] = useState<string | null>(null)
  const [name, setName] = useState("")
  const [gender, setGender] = useState("")
  const [description, setDescription] = useState("")
  const [parentCategory, setParentCategory] = useState<string>("")
  const [slug, setSlug] = useState("")
  const [id, setId] = useState<string | null>(null)

  const createCatToggler = (e: any) => {
    e.preventDefault()
    setOpenModal(true)
    setId(null)
    setName("")
    setGender("")
    setDescription("")
    setParentCategory("")
  }

  const toggleModal = () => {
    setOpenModal(!openModal)
  }

  const statusUpdateHandler = (e: any) => {
    e.preventDefault()
    dispatch(update_category_status({ slug: slug }))

    setTimeout(() => {
      setOpenModal(false)
    }, 1000)
  }

  const editHandler = (e: any, data: CategoryInterface) => {
    e.preventDefault()
    setId(String(data._id))
    setSlug(data.slug)
    setGender(data.gender)
    setName(data.name)
    setDescription(data.description)
    setParentCategory(data.parentCategory || "")
    setOpenModal(true)
  }

  const submitHandler = async (e: any) => {
    e.preventDefault()
    if (id === null) {
      if (name !== "") {
        if (parentCategory) {
          // Send the name, description, and parentCategoryId to post_subcategories if parentCategory is not empty
          const payload = {
            name,
            gender,
            description,
            category: parentCategory,
          }
          await dispatch(post_subcategories(payload))
          // console.log("sub-category data sent", payload)
        } else {
          // If parentCategory is empty, create the category as usual
          let data = {
            name,
            gender,
            description,
            parentCategory: null,
          }
          await dispatch(post_category(data)).unwrap()
          // console.log("category data sent", data)
        }
        setName("")
        setGender("")
        setDescription("")
        setParentCategory("")
        setOpenModal(false)
      } else {
        setMessage("Please fill in all details")
      }
    } else {
      let data = {
        name,
        slug,
        gender,
        description,
        parentCategory: parentCategory || null,
      }
      await dispatch(update_category(data))
      setOpenModal(false)
    }
  }

  // console.log("parent", parentCategory)

  const catHead = ["S/N", "Name", "Description", "View", "Status", "Edit", "Delete"]

  useEffect(() => {
    dispatch(get_categories())

    return () => {
      dispatch(resetCategories())
    }
  }, [dispatch, category])

  const genderOptions = [
    {
      id: 1,
      name: "Male",
      value: "male",
    },
    {
      id: 2,
      name: "Female",
      value: "female",
    },
    {
      id: 3,
      name: "Unisex",
      value: "unisex",
    },
  ]

  const prodDeleteHandler = (e: any, id: string) => {
    e.preventDefault()
    if (window.confirm("Are you sure?")) {
      dispatch(delete_category({ id: id }))

      setTimeout(() => {
        setOpenModal(false)
      }, 1000)
    }
  }

  return (
    <section className="user_dashboard">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-right">
            <Link
              to="#"
              onClick={(e) => {
                createCatToggler(e)
              }}
              className="btn btn_site_dark"
            >
              Create Category
            </Link>
          </div>

          <div className="col-12">
            {is_fetching ? (
              <Loader />
            ) : (
              <>
                {categories && categories.length > 0 ? (
                  <div className="mt-5 table-responsive shadow-sm dash_table">
                    <table className="table table-bordered table-striped">
                      <thead className="">
                        <tr>
                          {catHead.map((cate, i) => (
                            <th key={i}>{cate}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {categories.map((l: CategoryInterface, i: number) => (
                          <tr key={l._id}>
                            <td>{++i}</td>
                            <td>{l.name}</td>
                            <td>{l.description}</td>
                            <td>
                                <Link to={`/account/categories/${l._id}`}>
                                  <GrFormView size={24} />
                                </Link>
                              </td>
                            <td>
                              {l.status === true ? (
                                <span className="status active_status">
                                  Active
                                </span>
                              ) : (
                                <span className="status inactive_status">
                                  Inactive
                                </span>
                              )}
                            </td>
                            <td>
                              <Link to="#" onClick={(e) => editHandler(e, l)}>
                                <GrFormEdit size={20} />
                              </Link>
                            </td>
                            <td>
                                <Link
                                  to="#"
                                  onClick={(e) => prodDeleteHandler(e, l._id)}
                                >
                                  <GrTrash size={20} />
                                </Link>
                              </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="card mt-3">
                    <div className="card-body">
                      <p className="nothing">No Categories found</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

        </div>
      </div>
      <ModalComponent
        open={openModal}
        toggle={toggleModal}
        size="lg"
        title={` ${id ? "Edit" : "Add"} Category`}
      >
        <div className="login_form">
          <form className="tagForm" onSubmit={submitHandler}>
            <label className="label">Name</label>
            <input
              type="text"
              value={name}
              className="form-control mb-3"
              onChange={(e) => setName(e.target.value)}
            />

            <label className="label">Gender</label>
            <select
              className="form-select mb-3"
              required
              onChange={(e) => setGender(e.target.value)}
              defaultValue={gender}
              
            >
              <option value="" disabled selected>-- Select --</option>
              {genderOptions?.map((gender) => (
                <option value={gender.value} key={gender.id}>
                  {gender.name}
                </option>
              ))}
            </select>

            <label className="label">Description</label>
            <textarea
              value={description}
              className="form-control mb-3"
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>

            <label className="label">Parent Category</label>
            <select
              value={parentCategory}
              className="form-control mb-3"
              onChange={(e) => setParentCategory(e.target.value)}
            >
              <option value="">None</option>
              {categories?.map((cat) => (
                <option key={cat._id} value={cat._id}>
                  {cat.name}
                </option>
              ))}
            </select>

            <div className="d-flex justify-content-between mt-4">
              <div>
                <button disabled={is_loading} className="btn btn_site_dark">
                  {is_loading ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : id ? (
                    "Update"
                  ) : (
                    "Create"
                  )}
                </button>
                <p style={{ fontSize: "12px", color: "red" }}> {message} </p>
              </div>
              {id && (
                <div>
                  <button
                    className="btn btn_site"
                    onClick={statusUpdateHandler}
                    disabled={is_loading ? true : false}
                  >
                    Update Status
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      </ModalComponent>
    </section>
  )
}

export default DashboardCategory
