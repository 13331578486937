/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import ModalComponent from "../../components/ModalComponent"
import Loader from "../../components/Preloader"
import { GrFormEdit } from "react-icons/gr"
import {
  get_brands,
  get_category_brand,
  post_brand,
  resetBrands,
  update_brand,
  update_brand_status,
} from "../../features/brand/brandSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { BrandsInterface } from "../../features/brand/brandState"
import {
  get_categories,
  resetCategories,
} from "../../features/category/categorySlice"

const DashboardSizes = () => {
  const dispatch = useAppDispatch()

  const { brand, brands, cat_brands, is_loading, is_fetching } = useAppSelector(
    (state) => state.store_brands,
  )

  const { categories } = useAppSelector((state) => state.store_categories)

  const [openModal, setOpenModal] = useState(false)
  const [message, setMessage] = useState<string | null>(null)
  const [name, setName] = useState("")
  const [category, setCategory] = useState("")
  const [gender, setGender] = useState("")
  const [filterCategory, setFilterCategory] = useState("")
  const [slug, setSlug] = useState("")
  const [id, setId] = useState<string | null>(null)

  const createBrandToggler = (e: any) => {
    e.preventDefault()
    setOpenModal(true)
    setId(null)
    setName("")
    setGender("")
    setCategory("")
  }

  const toggleModal = () => {
    setOpenModal(!openModal)
  }

  const statusUpdateHandler = (e: any) => {
    e.preventDefault()
    // if (window.confirm("Are you sure?")) {
    dispatch(update_brand_status({ slug: slug }))

    setTimeout(() => {
      setOpenModal(false)
    }, 1000)
    // }
  }

  const editHandler = (e: any, data: BrandsInterface) => {
    e.preventDefault()
    setId(String(data._id))
    setSlug(data.slug)
    setName(data.name)
    setGender(data.gender)
    // setCategory(String(data.category._id))
    setOpenModal(true)
  }

  const submitHandler = (e: any) => {
    e.preventDefault()
    if (id === null) {
      if (name !== "") {
        let data = {
          name,
          gender
          // category,
        }
        // console.log("brand data sent:", data);
        
        dispatch(post_brand(data))
        setTimeout(() => {
          setName("")
          setGender("")
          setOpenModal(false)
        }, 1000)
      } else {
        setMessage("Please fill in all details")
      }
    } else {
      let data = {
        name,
        gender,
        slug,
        // category,
      }

      dispatch(update_brand(data))
      setTimeout(() => {
        setOpenModal(false)
      }, 1000)
    }
  }

  const brandHead = ["S/N", "Name", "Status", "Edit"]
  // console.log("brands:", brands);
  

  useEffect(() => {
    dispatch(get_brands())
    dispatch(get_categories())

    return () => {
      dispatch(resetBrands())
      dispatch(resetCategories())
    }
  }, [dispatch, brand])

  useEffect(() => {
    let data = {
      id: filterCategory,
    }
    if (filterCategory) dispatch(get_category_brand(data))
  }, [filterCategory])

  // console.log(brands);
  

  return (
    <section className="user_dashboard">
      <div className="container">
        <div className="row">
          <div className="col-md-6 text-right">
            <Link
              to="#"
              onClick={(e) => {
                createBrandToggler(e)
              }}
              className="btn btn_site_dark"
            >
              Create Size
            </Link>
          </div>

          {/* <div className="col-md-6">
            <div className="row gap-0">
              <div className="col-11">
                <select
                  className="form-select"
                  onChange={(e) => setFilterCategory(e.target.value)}
                  defaultValue={category}
                >
                  <option value="">Filter by Category</option>
                  {categories?.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-1 text-left pt-2 pr-3">
                {is_loading && <i className="fas fa-spinner fa-spin"></i>}
              </div>
            </div>
          </div> */}

          <div className="col-12">
            {is_fetching ? (
              <Loader />
            ) : (
              <>
                {brands && brands.length > 0 ? (
                  <div className="mt-5 table-responsive shadow-sm dash_table">
                    <table className="table table-bordered table-striped">
                      <thead className="">
                        <tr>
                          {brandHead.map((cate, i) => (
                            <th key={i}>{cate}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {filterCategory ?
                          <>
                            {cat_brands?.map((l: BrandsInterface, i: number) => (
                              <tr key={l._id}>
                                <td>{++i}</td>
                                <td>{l.name}</td>
                                {/* <td>{l.category?.name}</td> */}
                                <td>
                                  {l.status === true ? (
                                    <span className="status active_status">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="status inactive_status">
                                      Inactive
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <Link to="#" onClick={(e) => editHandler(e, l)}>
                                    <GrFormEdit size={20} />
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </>
                          :
                          <>
                            {brands?.map((l: BrandsInterface, i: number) => (
                              <tr key={l._id}>
                                <td>{++i}</td>
                                <td>{l.name}</td>
                                {/* <td>{l.category?.name}</td> */}
                                <td>
                                  {l.status === true ? (
                                    <span className="status active_status">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="status inactive_status">
                                      Inactive
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <Link to="#" onClick={(e) => editHandler(e, l)}>
                                    <GrFormEdit size={20} />
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </>
                        }
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="card mt-3">
                    <div className="card-body">
                      <p className="nothing">No Sizes found</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <ModalComponent
        open={openModal}
        toggle={toggleModal}
        size="lg"
        title={` ${id ? "Edit" : "Add"} Size`}
      >
        <div className="login_form">
          <form className="tagForm" onSubmit={submitHandler}>
            <label className="label">Name</label>
            <input
              type="text"
              value={name}
              required
              className="form-control mb-3"
              onChange={(e) => setName(e.target.value)}
            />

            <div className="d-flex justify-content-between mt-4">
              <div>
                <button disabled={is_loading} className="btn btn_site_dark">
                  {is_loading ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : id ? (
                    "Update"
                  ) : (
                    "Create"
                  )}
                </button>
                <p style={{ fontSize: "12px", color: "red" }}> {message} </p>
              </div>
              {id && (
                <div>
                  <button
                    className="btn btn_site"
                    onClick={statusUpdateHandler}
                    disabled={is_loading ? true : false}
                  >
                    Update Status
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      </ModalComponent>
    </section>
  )
}

export default DashboardSizes
