/* eslint-disable prettier/prettier */
import { BrandsInterface } from "../brand/brandState"
import { CategoryInterface, SubCategoryInterface } from "../category/categoryState"
import { UserInterface } from "../user/userState"

export interface ProductInitialInterface {
  products: ProductsInterface | null
  products_no_pag: ProductsInterface | null
  favourites: FavouritesInterface | null
  del_product: ProductsInfoInterface | null
  product: ProductsInfoInterface | null
  is_loading: boolean
  is_loading_review: boolean
  delete_review: string | null
  product_review: ReviewsInterface | null
  all_reviews: ReviewsInterface | null
}

export const ProductInitialState: ProductInitialInterface = {
  products: null,
  products_no_pag: null,
  favourites: null,
  product: null,
  del_product: null,
  is_loading: false,
  is_loading_review: false,
  delete_review: null,
  product_review: null,
  all_reviews: null,
}

export interface ProductsInterface {
  length: number
  map(arg0: (l: ProductsInfoInterface, i: number) => import("react/jsx-runtime").JSX.Element): import("react").ReactNode
  availableProducts: ProductsInfoInterface[] | null
  meta: MetaInterface
  products: ProductsInfoInterface[]
  createdAt: string
}

export interface ProductsInfoInterface {
  quantity: string
  _id: number
  brand_name: BrandsInterface
  category: CategoryInterface
  sub_category: SubCategoryInterface
  vendor: UserInterface
  description: string
  gender: string
  sizes: string[]
  colors: string[]
  image: string[]
  name: string
  new_product: boolean
  price: string
  old_price: string
  slug: string
  status: boolean
  createdAt: string
}

export interface MetaInterface {
  page: number
  pages: number
  total: number
}

export interface ReviewsInterface {
  meta: MetaInterface
  review: ReviewInfoInterface[]
}

export interface ReviewInfoInterface {
  _id: string
  email: string
  message: string
  name: string
  phoneNumber: string
  product: ProductsInfoInterface
  rating: number
  createdAt: string
}

export interface FavouritesInterface {
  meta: MetaInterface
  favourites: FavouritesInfoInterface[]
}

export interface FavouritesInfoInterface {
  _id: number
  product: ProductsInfoInterface
  createdAt: string
  user?: UserInterface
}
