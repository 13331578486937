/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { UserInitialState } from "./userState"
import { authHeader } from "../../utils/headers"
import config from "../../utils/config"
import { toast } from "react-hot-toast"
import { store } from "../../app/store"
import { reset } from "../authentication/authenticationSlice"

let url = config.liveUrl

export const create_vendor = createAsyncThunk(
  "user/create_vendor",
  async (
    payload: {
      email: string
      phoneNumber: string
      businessName: string
      password: string
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.post(`${url}/admin/users`, payload, {
        headers: authHeader(token ? token : "123"),
      })

      toast.success(response.data.message, {
        position: "top-right",
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const get_users = createAsyncThunk(
  "user/get_users",
  async (
    payload: {
      page?: number
      keyword?: string
      selected?: boolean
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    let link

    link = `${url}/users/all?pageNumber=${payload?.page}&pageSize=10&status=${payload?.selected}`

    if (payload?.keyword !== "")
      link = `${url}/users/all?pageNumber=${payload?.page}&pageSize=10&status=${payload?.selected}&keyword=${payload?.keyword}`

    try {
      const response = await axios.get(link, {
        headers: authHeader(token ? token : "123"),
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const get_users_no_pag = createAsyncThunk(
  "user/get_users_no_pag",
  async (_, thunkAPI) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    let link

    link = `${url}/users/all/no-pag`

    try {
      const response = await axios.get(link, {
        headers: authHeader(token ? token : "123"),
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const update_user_status = createAsyncThunk(
  "user/update_user_status",
  async (
    payload: {
      id: number
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.put(
        `${url}/admin/users/status/${payload.id}`,
        payload,
        {
          headers: authHeader(token ? token : "123"),
        },
      )

      toast.success(response.data.message, {
        position: "top-right",
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const get_user_details = createAsyncThunk(
  "user/get_user_details",
  async (
    payload: {
      id: string | undefined
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.get(`${url}/users/${payload.id}`, {
        headers: authHeader(token ? token : "123"),
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const get_user_orders = createAsyncThunk(
  "user/get_user_orders",
  async (
    payload: {
      id: string | undefined
    },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI
    let token: string | null = store.getState()?.auth?.token

    try {
      const response = await axios.get(`${url}/orders/users/${payload.id}`, {
        headers: authHeader(token ? token : "123"),
      })

      return response.data
    } catch (error: any) {
      const message = error.response
        ? error.response.data.message
        : "Something went wrong"
      if (message === "Not Authorized") {
        dispatch(reset())
      }
      toast.error(message, { position: "top-right" })
      return message
    }
  },
)

export const { reducer: UserReducer, actions } = createSlice({
  name: "user",
  initialState: UserInitialState,
  reducers: {
    resetUsers: (state) => {
      state.is_loading = false
      state.users = null
      state.user = null
      state.users_no_pag = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(create_vendor.fulfilled, (state, action) => {
      state.user = action.payload.data
      state.is_loading = false
    })
    builder.addCase(create_vendor.pending, (state) => {
      state.is_loading = true
    })
    builder.addCase(create_vendor.rejected, (state) => {
      state.is_loading = false
    })
    builder.addCase(get_users.fulfilled, (state, action) => {
      state.users = action.payload.data
      state.is_loading = false
    })
    builder.addCase(get_users.pending, (state) => {
      state.is_loading = true
    })
    builder.addCase(get_users.rejected, (state) => {
      state.is_loading = false
    })
    builder.addCase(get_users_no_pag.fulfilled, (state, action) => {
      state.users_no_pag = action.payload.data
      state.is_loading = false
    })
    builder.addCase(get_users_no_pag.pending, (state) => {
      state.is_loading = true
    })
    builder.addCase(get_users_no_pag.rejected, (state) => {
      state.is_loading = false
    })
    builder.addCase(update_user_status.fulfilled, (state, action) => {
      state.user = action.payload.data
      state.is_loading = false
    })
    builder.addCase(update_user_status.pending, (state) => {
      state.is_loading = true
    })
    builder.addCase(update_user_status.rejected, (state) => {
      state.is_loading = false
    })

    builder.addCase(get_user_details.fulfilled, (state, action) => {
      state.user = action.payload.data
      state.is_loading = false
    })
    builder.addCase(get_user_details.pending, (state) => {
      state.is_loading = true
    })
    builder.addCase(get_user_details.rejected, (state) => {
      state.is_loading = false
    })
    builder.addCase(get_user_orders.fulfilled, (state, action) => {
      state.user_orders = action.payload.data
      state.is_loading_orders = false
    })
    builder.addCase(get_user_orders.pending, (state) => {
      state.is_loading = true
    })
    builder.addCase(get_user_orders.rejected, (state) => {
      state.is_loading = false
    })
  },
})

export const { resetUsers } = actions
