import React, { useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import AuthLayout from "../../components/Auth/AuthLayout"
import { Field, Formik, FormikHelpers } from "formik"
import * as Yup from "yup"
import AuthHeaderText from "../../components/Auth/AuthHeaderText"
import { Link, useNavigate } from "react-router-dom"
import {
  admin_signup,
  resetAuth,
} from "../../features/authentication/authenticationSlice"

const Signup = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [justClicked, setJustClicked] = useState<Boolean>(false)
  const { isSigningIn, signup_admin } = useAppSelector((state) => state.auth)

  interface SignupFormValuesInterface {
    email: string
    name: string
    password: string
  }

  const SignupFormSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("You need to enter a valid email"),
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("You need to enter a valid text"),
    password: Yup.string()
      .min(6, "length of characters must be more than 6.")
      .required("You need to enter password"),
  })

  const SignupForm = useRef(null)

  const signupPressed = (
    signupFormValues: SignupFormValuesInterface,
    { setSubmitting, setFieldError }: FormikHelpers<SignupFormValuesInterface>,
  ) => {
    setJustClicked(true)
    dispatch(
      admin_signup({
        email: signupFormValues.email,
        name: signupFormValues.name,
        password: signupFormValues.password,
      }),
    )
  }

  useEffect(() => {
    if (signup_admin && justClicked) {
      setTimeout(() => {
        navigate("/")
      }, 2000)
    }
    return () => {
      dispatch(resetAuth())
    }
  }, [justClicked, signup_admin])

  return (
    <main>
      <AuthLayout>
        <AuthHeaderText text="Create Your Admin Account" />

        <div className="auth_form">
          <Formik
            onSubmit={signupPressed}
            innerRef={SignupForm}
            validationSchema={SignupFormSchema}
            initialValues={{
              email: "",
              password: "",
              name: "",
            }}
          >
            {({ handleSubmit, errors, touched }) => {
              return (
                <div>
                  <div className="auth_form_input">
                    <Field
                      className="form-control"
                      placeholder="Name"
                      name="name"
                    />
                    {errors.name && touched.name ? <p>{errors.name}</p> : null}
                  </div>

                  <div className="auth_form_input">
                    <Field
                      className="form-control"
                      placeholder="Email address"
                      name="email"
                      type="text"
                    />
                    {errors.email && touched.email ? (
                      <p>{errors.email}</p>
                    ) : null}
                  </div>

                  <div className="auth_form_input">
                    <Field
                      className="form-control"
                      placeholder="Password"
                      type="password"
                      name="password"
                    />
                    {errors.password && touched.password ? (
                      <p>{errors.password}</p>
                    ) : null}
                  </div>

                  <div className="text-center auth_btn_div auth_btn_div2 mt-4">
                    <button
                      onClick={() => handleSubmit()}
                      className="btn site_btn"
                    >
                      {isSigningIn ? (
                        <i className="fas fa-spinner fa-spin"></i>
                      ) : (
                        <span> Create</span>
                      )}
                    </button>
                    <div className="mt-3 existing">
                      <span>Already signed up?</span>
                      <span className="text_gold">
                        <Link to="/"> Login now</Link>
                      </span>
                    </div>
                  </div>
                </div>
              )
            }}
          </Formik>
        </div>
      </AuthLayout>
    </main>
  )
}

export default Signup
