import dayjs, { Dayjs } from "dayjs"

export function comma(Num: string) {
  //function to add commas to textboxes
  // $("#value").val($("#value").val().replace(/[^0-9,]/g, ''));
  Num = parseFloat(Num).toFixed(0)
  Num += ""
  Num = Num.replace(",", "")
  Num = Num.replace(",", "")
  Num = Num.replace(",", "")
  Num = Num.replace(",", "")
  Num = Num.replace(",", "")
  Num = Num.replace(",", "")
  // Num = Num.replace(',', '');
  let x, x1, x2
  x = Num.split(".")
  x1 = x[0]
  x2 = x.length > 1 ? "." + x[1] : ""
  var rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) x1 = x1.replace(rgx, "$1" + "," + "$2")
  return x1 + x2
}
export function myDate(dat: string | Date | undefined) {
  return dayjs(dat).locale("en").format("DD MMM YYYY")
}

export const formatDate = (
  date_string: string | Dayjs,
  full: boolean = false,
) => {
  date_string = dayjs(date_string)

  return date_string.isSame(dayjs(), "day")
    ? date_string.format("h:mm A")
    : date_string.isSame(dayjs(), "year")
    ? date_string.format(`MMM D${full ? ", h:mmA" : ""}`)
    : date_string.format(`MMM D, YYYY ${full ? "h:mmA" : ""}`)
}
