/* eslint-disable prettier/prettier */
import React from "react"
import menu, { MenuInterface } from "./menu"
import NavCollapse from "./NavCollapse"
import NavLinks from "./NavLinks"

const Navigation = ({
  // toggleOpenHalf,
  openHalf,
  handleToggle,
  accountType,
}: {
  // toggleOpenHalf: (e: any) => void;
  handleToggle: (e: any) => void
  openHalf: boolean
  accountType: boolean
}) => {
  return (
    <ul className="side-nav">
      {menu.map((m: MenuInterface, index: number) =>
        m?.children ? (
          <React.Fragment key={index}>
            {accountType && m?.accountType.includes(accountType) && (
              <NavCollapse
                key={m.id}
                link={m}
                // toggleOpenHalf={(e: any) =>
                //     toggleOpenHalf(e)
                // }
                handleToggle={(e: any) => handleToggle(e)}
                openHalf={openHalf}
              />
            )}
          </React.Fragment>
        ) : (
          <React.Fragment key={index}>
            {accountType && m?.accountType.includes(accountType) && (
              <NavLinks
                key={m.id}
                link={m}
                // toggleOpenHalf={(e: any) =>
                //     toggleOpenHalf(e)
                // }
                handleToggle={(e: any) => handleToggle(e)}
                openHalf={openHalf}
              />
            )}
          </React.Fragment>
        ),
      )}
    </ul>
  )
}

export default Navigation
