/* eslint-disable prettier/prettier */
export interface BrandInitialInterface {
  brands: BrandsInterface[] | null
  cat_brands: BrandsInterface[] | null
  // category_brands: BrandsInterface[] | null
  brand: BrandsInterface | null
  is_loading: boolean
  is_fetching: boolean
}

export const BrandInitialState: BrandInitialInterface = {
  brands: null,
  cat_brands: null,
  // category_brands: null,
  brand: null,
  is_loading: false,
  is_fetching: false,
}

export interface BrandsInterface {
  _id: string
  status: boolean
  slug: string
  name: string
  gender: string
  // category: any
}
