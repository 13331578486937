/* eslint-disable prettier/prettier */
import React, { ReactNode } from "react"

const TextFlex = ({
  title,
  para,
}: {
  title?: string
  para: string | undefined | ReactNode
}) => {
  return (
    <div className="mb-3">
      <div
        className="w-100  px-3 py-2"
        style={{
          border: "1px solid #ccc",
          borderRadius: "1rem",
          alignItems: "",
          display: "flex",
          gap: "5%",
          justifyContent: "space-between"
        }}
      >
        <h6  className="mb-0 ">{title}:</h6>
        <p  className="mb-0  "> {para} </p>
      </div>
    </div>
  )
}

export default TextFlex
