import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const ModalComponent = ({
    children,
    open,
    toggle,
    title,
    className,
    centered,
    size,
}: {
    children: any;
    open: boolean;
    toggle: any;
    title?: string;
    className?: string;
    centered?: boolean;
    size?: string;
}) => {
    return (
        <>
            <Modal
                isOpen={open}
                toggle={toggle}
                centered={centered}
                size={size}
            >
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className={className}>{children}</ModalBody>
            </Modal>
        </>
    );
};

export default ModalComponent;
