/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import Loader from "../../components/Preloader"
import { GrFormView } from "react-icons/gr"
import { get_orders, resetOrders } from "../../features/order/orderSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { OrderInterface } from "../../features/order/orderState"
import { comma, myDate } from "../../utils/helper"
import Pagination from "../../components/Pagination"

const DashboardOrders = () => {
  const dispatch = useAppDispatch()

  const pageNumber =
    Number(new URLSearchParams(useLocation().search).get("page")) || 1

  const [selected, setSelected] = useState<string>("")
  const [isPaid, setIsPaid] = useState<string>("")

  const { orders, is_loading } = useAppSelector((state) => state.store_orders)

  const brandHead = [
    "S/N",
    "Customer",
    "Address",
    "Total Price",
    "Payment Status",
    "Created At",
    "View Details",
  ]

  useEffect(() => {
    dispatch(
      get_orders({
        page: pageNumber,
        selected:
          selected === "true" ? true : selected === "false" ? false : "",
        isPaid: isPaid === "true" ? true : isPaid === "false" ? false : "",
      }),
    )

    return () => {
      dispatch(resetOrders())
    }
  }, [dispatch, selected, isPaid, pageNumber])

  return (
    <section className="user_dashboard">
      <div className="container">
        <div className="row">
          <div className="col-12 mt-3">
            <div className="row">
              <div className="col-md-6 col-sm-6">
                {/* <select
                  value={selected}
                  onChange={(e) => setSelected(e.target.value)}
                  className="form-select"
                  style={{ fontSize: "12px" }}
                >
                  <option value="">Status</option>
                  <option value={"true"}>Active</option>
                  <option value={"false"}>Inactive</option>
                </select> */}
              </div>
              <div className="col-md-6 col-sm-6">
                <select
                  value={isPaid}
                  onChange={(e) => setIsPaid(e.target.value)}
                  className="form-select"
                  style={{ fontSize: "12px" }}
                >
                  <option value="">Payment Status</option>
                  <option value={"true"}>Paid</option>
                  <option value={"false"}> Not Paid</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-12">
            {is_loading ? (
              <Loader />
            ) : (
              <>
                {orders && orders?.orders?.length > 0 ? (
                  <div className="mt-5 table-responsive shadow-sm dash_table">
                    <table className="table table-bordered table-striped">
                      <thead className="">
                        <tr>
                          {brandHead.map((cate, i) => (
                            <th key={i}>{cate}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {orders?.orders?.map((l: OrderInterface, i: number) => (
                          <tr key={l._id}>
                            <td>{(orders?.meta.page - 1) * 3 + i + 1}</td>
                            <td>
                              <Link to={`/account/users/${l.user?._id}`}>
                                {l.user.firstName} {l.user.lastName} 
                                {/* {l.user.email}{" "} */}
                              </Link>
                            </td>
                            <td>
                              {l.deliveryAddress.address},{" "}
                              {l.deliveryAddress.city},{" "}
                              {l.deliveryAddress.country}.
                            </td>
                            <td> &#8358;{comma(String(l.totalPrice))} </td>
                            <td>
                              {l.isPaid === true ? (
                                <span className="status active_status">
                                  Paid
                                </span>
                              ) : (
                                <span className="status inactive_status">
                                  Not Paid
                                </span>
                              )}
                            </td>
                            <td>{myDate(l.createdAt)}</td>
                            <td>
                              <Link to={`/account/orders/${l._id}`}>
                                <GrFormView size={24} />
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {orders && orders?.meta.pages > 1 && (
                      <>
                        <Pagination
                          currentPage={Number(orders.meta.page)}
                          totalCount={Number(orders.meta.total)}
                          pageSize={10}
                          pathname={"/account/orders/"}
                        />
                      </>
                    )}
                  </div>
                ) : (
                  <div className="card mt-3">
                    <div className="card-body">
                      <p className="nothing">No Orders found</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default DashboardOrders
