/* eslint-disable prettier/prettier */
import React, { useEffect, useLayoutEffect, useState } from "react"
import { Outlet, Navigate, useLocation, useNavigate } from "react-router-dom"
import { useAppSelector } from "./app/hooks"
import OutsideClick from "./components/OutsideClick"
import Sidebar from "./components/Sidebar"
import Header from "./components/Header"

const AdminPath = () => {
  const RouteToTop = () => {
    window.scrollTo(0, 0)
  }

  useLayoutEffect(() => {
    RouteToTop()
  }, [window.location.href])

  const navigate = useNavigate()
  const { admin } = useAppSelector((state) => state.auth)

  const [open, setOpen] = useState(false)

  const [openHalf, setOpenHalf] = useState(false)

  const toggle = (e: any) => {
    e.preventDefault()
    setOpen(!open)
  }

  const toggleHalf = (e: any) => {
    e.preventDefault()
    setOpenHalf(!openHalf)
  }

  const closeNavbar = (e: any) => {
    // e.preventDefault()
    setOpen(false)
  }

  const closeNavbar2 = (e: any) => {
    e.preventDefault()
    setOpen(false)
  }

  const location = useLocation()

  const head = location.pathname.split("/")
  const title =
    head?.length <= 3
      ? head[head.length - 1]
      : `${head[2].slice(0, head[2].length - 1)} Details`

  useEffect(() => {
    if (!admin) {
      navigate("/")
    }
  }, [admin])

  return admin ? (
    <div className="wrapper" style={{ backgroundColor: "#FAFAFA" }}>
      <OutsideClick handleToggle={closeNavbar}>
        <Sidebar
          open={open}
          openHalf={openHalf}
          handleToggle={(e: any) => closeNavbar2(e)}
          toggleOpenHalf={(e: any) => toggleHalf(e)}
        />
      </OutsideClick>
      <div className={openHalf ? "main-page-open" : "main-page"}>
        <Header openMenu={toggle} title={title} openHalf={openHalf} />
        <div className="container-fluid">
          <div style={{ padding: "8rem 1rem 4rem 1rem" }}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Navigate to="/" />
  )
}

export default AdminPath
