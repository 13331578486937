/* eslint-disable prettier/prettier */
export interface NavMenuInterface {
  menu: MenuInterface[]
}

export interface MenuInterface {
  id: number
  name: string
  href: string
  icon: string
  accountType: boolean[]
  children?: any
}

const menu = [
  {
    id: 1,
    name: "Dashboard",
    href: "/account/dashboard",
    icon: "dash",
    accountType: [true, false],
    children: null,
  },
  // {
  //   id: 2,
  //   name: "Colors",
  //   href: "/account/colors",
  //   icon: "brands",
  //   accountType: [true],
  //   children: null,
  // },
  // {
  //   id: 3,
  //   name: "Sizes",
  //   href: "/account/sizes",
  //   icon: "brands",
  //   accountType: [true],
  //   children: null,
  // },
  {
    id: 4,
    name: "Brands",
    href: "/account/brands",
    icon: "brands",
    accountType: [true],
    children: null,
  },
  //   {
  //     id: 4,
  //     name: "More Services",
  //     href: "#",
  //     icon: "more-services",
  //     accountType: [false],
  //     children: [
  //       {
  //         id: 1,
  //         name: "Visa",
  //         href: "/account/visa",
  //         accountType: [true],
  //       },
  //       {
  //         id: 2,
  //         name: "Holidays",
  //         href: "/account/holidays",
  //         accountType: [true],
  //       },
  //       {
  //         id: 3,
  //         name: "Protocol Services",
  //         href: "/account/protocol-services",
  //         accountType: [true],
  //       },
  //     ],
  //   },
  {
    id: 5,
    name: "Categories",
    href: "/account/categories",
    icon: "categories",
    accountType: [true, false],
    children: null,
  },
  {
    id: 11,
    name: "Products",
    href: "/account/products",
    icon: "products",
    accountType: [true, false],
    children: null,
  },
  {
    id: 10,
    name: "Users",
    href: "/account/users",
    icon: "users",
    accountType: [true],
    children: null,
  },
  {
    id: 6,
    name: "Orders",
    href: "/account/orders",
    icon: "orders",
    accountType: [true, false],
    children: null,
  },
  {
    id: 6,
    name: "Favourites",
    href: "/account/favourites",
    icon: "favourites",
    accountType: [true, false],
    children: null,
  },
]

export default menu
