/* eslint-disable prettier/prettier */
import React from "react"
import { useAppSelector } from "../app/hooks"
import Navigation from "./Navs/Navigation"
import logo from "../assets/brand__logo.png"
import logo_head from "../assets/brand__logo.png"

const Sidebar = ({
  open,
  openHalf,
  handleToggle,
  toggleOpenHalf,
}: {
  open: boolean
  openHalf: boolean
  toggleOpenHalf: (e: any) => void
  handleToggle: (e: any) => void
}) => {
  const { admin } = useAppSelector((state) => state.auth)

  return (
    <div
      className={`sidebar ${open ? "open-m" : null} ${openHalf ? "openHalf-m" : null
        } `}
    >
      <div className="logo" style={{ margin: "20px 0 60px 0" }}>
        <img
          src={openHalf ? logo_head : logo}
          className="img-fluid"
          alt="logo"
          onClick={(e: any) => toggleOpenHalf(e)}
          style={{ cursor: "pointer" }}
        />
      </div>

      {admin && admin?.isSuperAdmin && (
        <Navigation
          // toggleOpenHalf={(e: any) => toggleOpenHalf(e)}
          openHalf={openHalf}
          handleToggle={(e: any) => handleToggle(e)}
          accountType={admin?.isSuperAdmin}
        />
      )}
    </div>
  )
}

export default Sidebar
