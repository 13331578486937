/* eslint-disable prettier/prettier */
import React, { useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import Loader from "../../components/Preloader"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  delete_product_review,
  get_product_details,
  get_product_reviews,
  resetProducts,
} from "../../features/product/productSlice"
import { comma, myDate } from "../../utils/helper"
import { ReviewInfoInterface } from "../../features/product/productState"
import { GrFormTrash } from "react-icons/gr"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import TextFlex from "../../components/TextFlex"

const DashboardProductDetails = () => {
  const dispatch = useAppDispatch()

  const params = useParams()

  const {
    product_review,
    product,
    is_loading,
    is_loading_review,
    delete_review,
  } = useAppSelector((state) => state.store_products)

  const tableHead = [
    "S/N",
    "Customer",
    "Product",
    "Message",
    "Rating",
    "Created At",
    "Delete",
  ]

  const deleteHandler = (e: any, id: string) => {
    e.preventDefault()
    if (window.confirm("Are you sure?")) {
      dispatch(delete_product_review({ id }))
    }
  }

  useEffect(() => {
    dispatch(get_product_details({ id: params.id }))
    dispatch(get_product_reviews({ id: params.id }))

    return () => {
      dispatch(resetProducts())
    }
  }, [dispatch, params.id, delete_review])

  return (
    <main className="user_dashboard">
      <div className="container">
        {is_loading ? (
          <Loader />
        ) : product ? (
          <div className="row">
            <div className="col-md-5 prod_carousel">
              <Carousel>
                {product?.image?.map((item, index) => (
                  <div key={index}>
                    <img
                      src={item}
                      alt="product"
                      style={{
                        height: "35rem",
                        objectFit: "cover",
                        width: "100%",
                      }}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
            <div className="col-md-7">
              <TextFlex title="Name of Product" para={product?.name} />
              <TextFlex
                title="Price of Product"
                para={
                  <p className="mb-0">
                    &#8358; ${comma(String(product.price))} (
                    <span style={{ textDecoration: "line-through" }}>
                      &#8358; ${comma(String(product.old_price))}
                    </span>
                    )
                  </p>
                }
              />
              {product.gender && (
                <TextFlex
                title="Product Gender"
                para={product?.gender}
              />
              )}
              {/* {product.colors && (
                <TextFlex
                title="Product color"
                para={product?.colors}
              />
              )}
              {product.sizes && (
                <TextFlex
                title="Product sizes"
                para={product?.sizes}
              />
              )} */}
              <TextFlex
                title="Product Brand"
                para={product?.brand_name?.name}
              />
              <TextFlex
                title="Product Category"
                para={product?.category?.name}
              />
              {product.sub_category && (
                <TextFlex
                title="Product Sub Category"
                para={product?.sub_category?.name}
              />
              )}
              {product?.quantity && (
                <TextFlex
                title="Quantity Left"
                para={product?.quantity}
              />
              )}
              {/* <TextFlex
                title="Product Vendor"
                para={product?.vendor?.businessName}
              /> */}
              <TextFlex
                title="New Product? "
                para={product?.status === true ? "Yes" : "No"}
              />
              <TextFlex title="Date Created" para={myDate(product.createdAt)} />
              <TextFlex title="Description" para={product?.description} />
            </div>
          </div>
        ) : null}

        <h5 className="mt-5"> Product Reviews </h5>
        {is_loading_review ? (
          <i className="fas fa-spinner fa-spin"></i>
        ) : product_review && product_review?.review?.length > 0 ? (
          <div className="mt-2 table-responsive shadow-sm dash_table">
            <table className="table table-bordered table-striped">
              <thead className="">
                <tr>
                  {tableHead.map((cate, i) => (
                    <th key={i}>{cate}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {product_review?.review?.map(
                  (l: ReviewInfoInterface, i: number) => (
                    <tr key={l._id}>
                      <td>{++i}</td>
                      <td>
                        {l.name} <br /> {l.email} <br /> {l.phoneNumber}{" "}
                      </td>
                      <td>{l.product?.name}</td>
                      <td>{l.message}</td>
                      <td>{l.rating}</td>
                      <td>{myDate(l.createdAt)}</td>

                      <td>
                        <Link to="#" onClick={(e) => deleteHandler(e, l._id)}>
                          <GrFormTrash size={20} />
                        </Link>
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <>No reviews found for this product</>
        )}
      </div>
    </main>
  )
}

export default DashboardProductDetails
