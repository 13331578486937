/* eslint-disable prettier/prettier */
import React, { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { get_users_no_pag, resetUsers } from "../../features/user/userSlice"
import {
  get_products_no_pag,
  resetProducts,
} from "../../features/product/productSlice"
import { get_brands, resetBrands } from "../../features/brand/brandSlice"
import {
  get_categories,
  resetCategories,
} from "../../features/category/categorySlice"
import { get_orders } from "../../features/order/orderSlice"

const Dashboard = () => {
  const dispatch = useAppDispatch()

  const { admin } = useAppSelector((state) => state.auth)
  const { brands } = useAppSelector((state) => state.store_brands)
  const { categories } = useAppSelector((state) => state.store_categories)
  const { products } = useAppSelector((state) => state.store_products)
  const { users_no_pag } = useAppSelector((state) => state.store_users)
  const { orders } = useAppSelector((state) => state.store_orders)

  // console.log("Products", products);
  

  const metrics = [
    {
      text: "ALL USERS",
      value:
        users_no_pag && users_no_pag?.users
          ? users_no_pag?.users?.filter((item) => item.type === "CUSTOMER")
              ?.length
          : 0,
    },
    {
      text: "ACTIVE USERS",
      value:
        users_no_pag && users_no_pag?.users
          ? users_no_pag?.users?.filter(
              (item) => item.active === true && item.type === "CUSTOMER",
            )?.length
          : 0,
    },
    {
      text: "ALL VENDORS",
      value:
        users_no_pag && users_no_pag?.users
          ? users_no_pag?.users?.filter((item) => item.type === "VENDOR")
              ?.length
          : 0,
    },
    {
      text: "ACTIVE VENDORS",
      value:
        users_no_pag && users_no_pag?.users
          ? users_no_pag?.users?.filter(
              (item) => item.active === true && item.type === "VENDOR",
            )?.length
          : 0,
    },
    { text: "BRANDS", value: brands?.length },
    { text: "CATEGORIES", value: categories?.length },
    { text: "PRODUCTS", value: products?.availableProducts ? products?.availableProducts.length : "" },
    { text: "ALL ORDERS", value: orders?.meta?.total },
    // { text: "COMPLETED ORDERS", value: products?.products.length },
    // { text: "PENDING ORDERS", value: products?.products.length },
  ]

  const Box = ({ text, value }: { text: string; value: number }) => {
    return (
      <div className="col-md-6">
        <div className="dashboard_box">
          <p> {text} </p>
          <h3> {value} </h3>
        </div>
      </div>
    )
  }

  useEffect(() => {
    dispatch(get_users_no_pag())
    dispatch(get_brands())
    dispatch(get_products_no_pag())
    dispatch(get_categories())
    dispatch(get_orders({ page: 1, isPaid: "", selected: "" }))

    return () => {
      resetBrands()
      resetCategories()
      resetProducts()
      resetUsers()
    }
  }, [dispatch])

  return (
    <main>
      <h4 className="mb-4">Hello, {admin?.name}...</h4>

      <div>
        <div className="container">
          <div className="row">
            {metrics?.map((item: any, index: number) => (
              <Box text={item.text} value={item.value} key={index} />
            ))}
          </div>
        </div>
      </div>
    </main>
  )
}

export default Dashboard
