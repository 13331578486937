/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
// import colorNamer from "color-namer"
import ModalComponent from "../../components/ModalComponent"
import Pagination from "../../components/Pagination"
import Loader from "../../components/Preloader"
import { GrFormEdit, GrFormView, GrTrash } from "react-icons/gr"
import {
  post_product,
  get_products,
  update_product,
  delete_product,
  update_product_status,
  resetProducts,
} from "../../features/product/productSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { ProductsInfoInterface } from "../../features/product/productState"
import {
  get_brands,
  get_category_brand,
  // get_category_brand,
  resetBrands,
} from "../../features/brand/brandSlice"
import {
  get_categories,
  get_subcategories,
  resetCategories,
} from "../../features/category/categorySlice"
import { get_users_no_pag } from "../../features/user/userSlice"
import { UserInterface } from "../../features/user/userState"
import toast from "react-hot-toast"
// import { CSVLink } from "react-csv"
import Fuse from "fuse.js"
import { DragDrop } from "../../components/Drag&Drop/Drag&Drop"

const DashboardProduct = () => {
  const dispatch = useAppDispatch()

  const pageNumber =
    Number(new URLSearchParams(useLocation().search).get("page")) || 1

  const { product, products, is_loading, del_product } =
    useAppSelector((state) => state.store_products)
  const { categories, sub_category } = useAppSelector(
    (state) => state.store_categories,
  )
  // console.log("Products", products);
  const { brands, cat_brands } = useAppSelector((state) => state.store_brands)
  const { users_no_pag } = useAppSelector((state) => state.store_users)

  const [openModal, setOpenModal] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string | null>(
    "All Categories"
  );
  const [message, setMessage] = useState<string | null>(null)
  const [name, setName] = useState("")
  const [slug, setSlug] = useState("")
  const [id, setId] = useState<string | null>(null)
  const [price, setPrice] = useState("")
  const [uploadedImg, setUploadedImg] = useState<string[]>([])
  const [old_price, setOld_Price] = useState("")
  const [quantity, setQuantity] = useState("")
  const [brand_name, setBrand_name] = useState("")
  const [subCategory_name, setSubCategory_name] = useState("")
  const [category, setCategory] = useState("")
  const [gender, setGender] = useState("")
  const [sizes, setSizes] = useState<string[]>([])
  const [colors, setColors] = useState<string[]>([])
  const [description, setDescription] = useState("")
  const [new_product, setNew_product] = useState(true)
  const [is_fetching, setIs_fetching] = useState(false)

  const [selected, setSelected] = useState<string>("true")
  const [keyword, setKeyword] = useState("")
  const [vendorId, setVendorId] = useState("")
  const [categoryId, setCategoryId] = useState("")
  const [brandId, setBrandId] = useState("")

  const [imageFile, setImageFile] = useState("")
  const [imageFormat, setImageFormat] = useState("")
  const [mainImage, setMainImage] = useState<string[] | Blob[]>([])
  const [uploadedImage, setUploadedImage] = useState("")
  const [imageList, setImageList] = React.useState<string[]>([])
  const [uploading, setUploading] = useState(false)
  const [fileLength, setFileLength] = useState<number>(0)

  const brandHead = [
    "S/N",
    "Image",
    "Name",
    "Category",
    "Brands",
    "Qty. Left",
    "Status",
    "View",
    "Edit",
    "Delete",
  ]

  const createBrandToggler = (e: any) => {
    e.preventDefault()
    setOpenModal(true)
    setId(null)
    setName("")
    setSizes([])
    setColors([])
    setGender("")
    setPrice("")
    setOld_Price("")
    setQuantity("")
    setBrand_name("")
    setCategory("")
    setSubCategory_name("")
    setUploadedImg([])
    setMainImage([])
    setImageList([])  
    setDescription("")
    // setNew_product(data.new_product)
    // window.location.reload()
  }

  const toggleModal = () => {
    setOpenModal(!openModal)
    setFileLength(0);
  }

  const statusUpdateHandler = (e: any) => {
    e.preventDefault()
    // if (window.confirm("Are you sure?")) {
    dispatch(update_product_status({ slug: slug }))

    setTimeout(() => {
      setOpenModal(false)
    }, 1000)
    // }
  }

  const prodDeleteHandler = (e: any, id: number) => {
    e.preventDefault()
    if (window.confirm("Are you sure?")) {
      dispatch(delete_product({ id: id }))

      setTimeout(() => {
        setOpenModal(false)
      }, 1000)
    }
  }

  const editHandler = (e: any, data: ProductsInfoInterface) => {
    e.preventDefault()
    setOpenModal(true)

    setId(String(data._id))
    setSlug(data.slug)
    setName(data.name)
    setSizes(data.sizes)
    setColors(data.colors)
    setGender(data.gender)
    setPrice(data.price)
    setOld_Price(data.old_price)
    setQuantity(data?.quantity)
    setBrand_name(String(data.brand_name._id))
    setCategory(String(data.category._id))
    setSubCategory_name(String(data.sub_category._id))
    setUploadedImg(data.image)
    setDescription(data.description)
    setNew_product(data.new_product)
  }

  const deleteMainImage = () => {
    setMainImage([])
  }

  const getBase64 = (file: any) => {
    return new Promise((resolve) => {
      let baseURL: string | ArrayBuffer | null = ""
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader?.result
        resolve(baseURL)
      }
    })
  }

  const handleFileInputChange = async (e: any) => {
    let file = e.target.files
    setFileLength(file.length)
    mainImage.push(...file)

    for (let i = 0; i < file.length; i++) {
      getBase64(file[i])
        .then((result) => {
          file[i]["base64"] = result
          let split = file[i].base64.split(",")
          setImageFile(split[1])
          let type = file[i].type.split("/")
          setImageFormat(type[1])
        })
        .catch((err) => {})
    }
  }

  // console.log("uploaded", uploadedImg)

  const uploadFileHandler = async (e: any) => {
    e.preventDefault();
    if (mainImage?.length > 0 && mainImage?.length < 12) {
      const data = new FormData();
      const urls: string[] = []; // Initialize an array to store the URLs
  
      for (const main of mainImage) {
        data.append("file", main);
        data.append("upload_preset", "dsquzv7s");
        data.append("cloud_name", "dzjazulfw");
  
        setUploading(true);
  
        try {
          const response = await fetch("https://api.cloudinary.com/v1_1/dzjazulfw/image/upload", {
            method: "POST",
            body: data,
          });
  
          const result = await response.json();
          setUploading(false);
  
          if (result.secure_url) {
            urls.push(result.secure_url); // Push each URL into the array
          }
  
          setUploadedImage(result.secure_url);
          setImageFile("");
        } catch (err) {
          setUploading(false);
          setImageFile("");
        }
      }
  
      // Once all images are uploaded, update the imageList with the accumulated URLs
      imageList.push(...urls); // Spread the URLs into the imageList array
    } else {
      alert("You can only select and upload a maximum of 12 images.");
    }
  };
  

  // console.log("image not uploaded", imageFile);
  // console.log("image uploaded", imageList);

  const submitHandler = async (e: any) => {
    e.preventDefault()
    setIs_fetching(true)
    setFileLength(0);

    if (imageFile) await uploadFileHandler(e)

    setTimeout(() => {
      if (id === null) {
        if (
          name !== "" &&
          price !== "" &&
          brand_name !== "" &&
          category !== "" &&
          imageList?.length !== 0
        ) {
          let data = {
            name,
            price,
            old_price,
            quantity,
            brand_name,
            category,
            colors,
            sub_category: subCategory_name,
            gender,
            sizes,
            description,
            // vendor,
            new_product,
            image: imageList,
            slug,
          }

          // console.log("product data being sent: ", data);
          // console.log({
          //   name,
          //   price,
          //   category,
          //   imageList
          // });
          setIs_fetching(true)
          setOpenModal(false)

          dispatch(post_product(data))

          setTimeout(() => {
            setName("")
            setPrice("")
            setOld_Price("")
            setBrand_name("")
            setCategory("")
            setQuantity("")
            setSubCategory_name("")
            setGender("")
            setColors([])
            setSizes([])
            setDescription("")
            setImageList([])
            setIs_fetching(false)
          }, 1000)
          // window.location.reload()
        } else {
          setMessage("Please fill in all details")
          toast.success("Please fill in all details", {
            position: "top-right",
          })
        }
      } else {
        let new_data = {
          name,
          price,
          old_price,
          quantity,
          brand_name,
          // vendor,
          id: id,
          category,
          sub_category: subCategory_name,
          colors,
          gender,
          sizes,
          description,
          new_product,
          image: fileLength >= 1 ? imageList : uploadedImg,
          slug,
        }
        setOpenModal(false)
        dispatch(update_product(new_data))
        // console.log("edit data being sent: ", new_data);

        setTimeout(() => {
          setName("")
          setPrice("")
          setOld_Price("")
          setQuantity("")
          setBrand_name("")
          setSubCategory_name("")
          setCategory("")
          setGender("")
          setColors([])
          setSizes([])
          setDescription("")
          setImageList([])
          setIs_fetching(false)

          setOpenModal(false)
        }, 1000)
      }
    }, 5000)
    setIs_fetching(false)
    // window.location.reload()
  }

  const searchHandler = (e: any) => {
    e.preventDefault()
    dispatch(
      get_products({
        // page: pageNumber,
        keyword,
        selected: selected === "true" ? true : false,
        // vendorId,
        categoryId,
        brandId,
      }),
    )
  }

  useEffect(() => {
    dispatch(
      get_products({
        // page: pageNumber,
        keyword,
        selected: selected === "true" ? true : false,
        // vendorId,
        categoryId,
        brandId,
      }),
    )
    dispatch(get_users_no_pag())
    dispatch(get_categories())
    dispatch(get_brands())

    return () => {
      dispatch(resetProducts())
    }
  }, [
    dispatch,
    product,
    del_product,
    is_fetching,
    pageNumber,
    selected,
    keyword,
    vendorId,
    categoryId,
    brandId,
  ])

  const GenderItems = [
    {
      id: 1,
      name: "Male",
      value: "male",
    },
    {
      id: 2,
      name: "Female",
      value: "female",
    },
    {
      id: 3,
      name: "Unisex",
      value: "unisex",
    },
  ]

  const SizeItems = [
    { name: "90cm", value: "90cm" },
    { name: "95cm", value: "95cm" },
    { name: "100cm", value: "100cm" },
    { name: "105cm", value: "105cm" },
    { name: "110cm", value: "110cm" },
    { name: "115cm", value: "115cm" },
    { name: "One Size", value: "One-Size" },
    { name: "XXS", value: "XXS" },
    {name: "XS", value: "XS" },
    { name: "S", value: "S" },
    { name: "M", value: "M" },
    { name: "L", value: "L" },
    { name: "XL", value: "XL" },
    { name: "XXL", value: "XXL" },
    { name: "3XL", value: "3XL" },
    { name: "4XL", value: "4XL" },
    { name: "5XL", value: "5XL" },
    { name: "6XL", value: "6XL" },
    { name: "7XL", value: "7XL" },
    { name: "8XL", value: "8XL" },
    { name: "9XL", value: "9XL" },
    { name: "10XL", value: "10XL" },
    { name: "30", value: "30" },
    { name: "31", value: "31" },
    { name: "32", value: "32" },
    { name: "33", value: "33" },
    { name: "34", value: "34" },
    { name: "35", value: "35" },
    { name: "35.5", value: "35.5" },
    { name: "36", value: "36" },
    { name: "36.5", value: "36.5" },
    { name: "37", value: "37" },
    { name: "37.5", value: "37.5" },
    { name: "38", value: "38" },
    { name: "38.5", value: "38.5" },
    { name: "39", value: "39" },
    { name: "39.5", value: "39.5" },
    { name: "40", value: "40" },
    { name: "40.5", value: "40.5" },
    { name: "41", value: "41" },
    { name: "41.5", value: "41.5" },
    { name: "42", value: "42" },
    { name: "42.5", value: "42.5" },
    { name: "43", value: "43" },
    { name: "43.5", value: "43.5" },
    { name: "44", value: "44" },
    { name: "44.5", value: "44.5" },
    { name: "45", value: "45" },
    { name: "45.5", value: "45.5" },
    { name: "46", value: "46" },
    { name: "46.5", value: "46.5" },
    { name: "47", value: "47" },
    { name: "47.5", value: "47.5" },
    { name: "48", value: "48" },
    { name: "48.5", value: "48.5" },
    { name: "49", value: "49" },
    { name: "49.5", value: "49.5" },
    { name: "50", value: "50" },
    { name: "50.5", value: "50.5" },
    { name: "51", value: "51" },
    { name: "51.5", value: "51.5" },
    { name: "52", value: "52" },
    { name: "52.5", value: "52.5" },
    { name: "53", value: "53" },
    { name: "53.5", value: "53.5" },
  ]

  const ColorItems = [
    { id: 1, name: "Red", value: "red" },
    { id: 2, name: "Blue", value: "blue" },
    { id: 3, name: "Green", value: "green" },
    { id: 4, name: "Yellow", value: "yellow" },
    { id: 5, name: "Black", value: "black" },
    { id: 6, name: "White", value: "white" },
    { id: 7, name: "Orange", value: "orange" },
    { id: 8, name: "Purple", value: "purple" },
    { id: 9, name: "Pink", value: "pink" },
    { id: 10, name: "Brown", value: "brown" },
    { id: 11, name: "Gray", value: "gray" },
    { id: 12, name: "Navy", value: "navy" },
    { id: 13, name: "Maroon", value: "maroon" },
    { id: 14, name: "Teal", value: "teal" },
    { id: 15, name: "Olive", value: "olive" },
    { id: 16, name: "Gold", value: "gold" },
    { id: 17, name: "Silver", value: "silver" },
    { id: 18, name: "Cyan", value: "cyan" },
    { id: 19, name: "Magenta", value: "magenta" },
    { id: 20, name: "Lime", value: "lime" },
    { id: 21, name: "Ivory", value: "ivory" },
    { id: 22, name: "Violet", value: "violet" },
    { id: 23, name: "Indigo", value: "indigo" },
    { id: 24, name: "Turquoise", value: "turquoise" },
    { id: 25, name: "Coral", value: "coral" },
  ]

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target
    if (checked) {
      setSizes([...sizes, value])
    } else {
      setSizes(sizes.filter((item) => item !== value))
    }
  }

  const handleColorCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value, checked } = e.target
    if (checked) {
      setColors([...colors, value])
    } else {
      setColors(colors.filter((item) => item !== value))
    }
  }

  useEffect(() => {
    if (category) dispatch(get_subcategories({ id: category }))

    // return () => {
    //   // dispatch(resetCategories())
    //   dispatch(get_subcategories({ id: category }))
    // }
  }, [dispatch, category])

  // console.log("sub category: ", sub_category);
  // console.log("products: ", products?.availableProducts);

  // const headers = [
  //   { label: "Name", key: "name" },
  //   { label: "Brand Name", key: "brand_name" },
  //   { label: "Category", key: "category" },
  //   { label: "Sub Category", key: "sub_category" },
  //   { label: "Colors", key: "colors" },
  //   { label: "Description", key: "description" },
  //   { label: "Gender", key: "gender" },
  //   { label: "Image", key: "image" },
  //   { label: "Price", key: "price" },
  //   { label: "Old Price", key: "old_price" },
  //   { label: "Sizes", key: "sizes" },
  //   { label: "Created At", key: "createdAt" },
  // ]
  // const csvData = sortedProducts ?? [];
  // console.log("something", users_no_pag);
  // console.log("products", products?.availableProducts);

  

  // Ensure availableProducts is an array before sorting
  const sortedProducts =
    products?.availableProducts
      ?.slice()
      .sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      ) || []

      const categoryNames = products?.availableProducts
      ? [
          "All Categories",
          ...new Set(
            products?.availableProducts.map((product: ProductsInfoInterface) => product?.category?.name)
          ),
        ]
      : [];

       // Handle category selection
  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
    // setCurrentPage(1); // Reset to first page on category change
  };

      // Filtered Products based on selected category, brand, price range, and search term
  const fuse = new Fuse(sortedProducts ?? [], {
    keys: ["name", "brand_name.name", "category.name"],
    threshold: 0.3,
  });

  const searchResults = searchTerm
    ? fuse.search(searchTerm).map((result: any) => result.item)
    : sortedProducts;

  // Filtered Products based on selected category and price range
  const filteredProducts = searchResults?.filter((product: ProductsInfoInterface) => {
    return (
      (selectedCategory === "All Categories" ||
        product?.category?.name === selectedCategory)
      //   (selectedBrand === "All Brands" ||
      //     product?.brand_name?.name === selectedBrand) &&
      // (selectedColor === "All Colors" ||
      //   (product.colors &&
      //     product.colors.includes(selectedColor ? selectedColor : ""))) &&
      // (selectedSize === "All Sizes" ||
      //   (product.sizes &&
      //     product.sizes.includes(selectedSize ? selectedSize : "")))
    );
  });

  const handleOrderChange = (newOrder: string[]) => {
    setUploadedImg(newOrder);  // Update the state with the new order
    // Optionally, persist the new order to the backend or local storage
  };

  // console.log("imageList uploaded images", imageList);

  return (
    <section className="user_dashboard">
      <div className="container">
        <div className="row">
          <div className="col-md-6 text-right">
            <Link
              to="#"
              onClick={(e) => {
                createBrandToggler(e)
              }}
              className="btn btn_site_dark me-3"
            >
              Create Product
            </Link>
            {/* {products && (
              <CSVLink
                data={csvData}
                headers={headers}
                className="btn btn_site_dark text-white"
                filename="all-products.csv"
              >
                Export CSV
              </CSVLink>
            )} */}
          </div>

          <div className="col-md-6 mb-2">
            <input
              type="input"
              placeholder="search"
              className="form-control"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              // onChange={(e) => setKeyword(e.target.value)}
              // onInput={searchHandler}
              style={{ fontSize: "12px" }}
            />
          </div>

          <div className="col-12 mt-3">
            <div className="row">

              <div className="col-md-3 col-sm-6">
                <select
                  // value={categoryId}
                  // onChange={(e) => setCategoryId(e.target.value)}
                  className="form-select"
                  style={{ fontSize: "12px" }}
                >
                  {/* <option value="">All Category</option> */}
                  {categoryNames?.map((item, index) => (
                    <option onClick={() => handleCategoryClick(item)} key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>

              {/* <div className="col-md-3 col-sm-6">
                <select
                  value={brandId}
                  onChange={(e) => setBrandId(e.target.value)}
                  className="form-select"
                  style={{ fontSize: "12px" }}
                >
                  <option value="">Brand</option>
                  {brands?.map((item, index) => (
                    <option key={index} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div> */}

              <div className="col-md-3 col-sm-6">
                <select
                  value={selected}
                  onChange={(e) => setSelected(e.target.value)}
                  className="form-select"
                  style={{ fontSize: "12px" }}
                >
                  <option value="" disabled selected>
                    Status
                  </option>
                  {/* <option value=''>All</option> */}
                  <option value={"true"}>active</option>
                  <option value={"false"}>Inactive</option>
                </select>
              </div>
            </div>
          </div>

          <div className="col-12">
            {is_loading ? (
              <Loader />
            ) : (
              <>
                {filteredProducts && filteredProducts.length > 0 ? (
                  <div className="mt-5 table-responsive shadow-sm dash_table">
                    <table className="table table-bordered table-striped text-center">
                      <thead className="">
                        <tr>
                          {brandHead.map((cate, i) => (
                            <th key={i}>{cate}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {filteredProducts.map(
                          (l: ProductsInfoInterface, i: number) => (
                            <tr key={l._id}>
                              <td>
                                {((products?.meta.page ?? 1) - 1) * 3 + i + 1}
                              </td>
                              <td>
                                <img
                                  src={l?.image[0]}
                                  alt=""
                                  style={{
                                    height: "2rem",
                                    width: "4rem",
                                    objectFit: "cover",
                                  }}
                                />
                              </td>
                              <td>{l?.name}</td>
                              <td>{l.category?.name}</td>
                              <td>{l.brand_name?.name}</td>
                              <td>{l?.quantity ? l.quantity : "None"}</td>
                              <td>
                                {l?.status === true ? (
                                  <span className="status active_status">
                                    Active
                                  </span>
                                ) : (
                                  <span className="status inactive_status">
                                    Inactive
                                  </span>
                                )}
                              </td>
                              <td>
                                <Link to={`/account/products/${l._id}`}>
                                  <GrFormView size={24} />
                                </Link>
                              </td>
                              <td>
                                <Link to="#" onClick={(e) => editHandler(e, l)}>
                                  <GrFormEdit size={24} />
                                </Link>
                              </td>
                              <td>
                                <Link
                                  to="#"
                                  onClick={(e) => prodDeleteHandler(e, l._id)}
                                >
                                  <GrTrash size={20} />
                                </Link>
                              </td>
                            </tr>
                          ),
                        )}
                      </tbody>
                    </table>

                    {products && filteredProducts.length > 1 && (
                      <>
                        <Pagination
                          currentPage={Number(products.meta.page)}
                          totalCount={Number(products.meta.total)}
                          pageSize={10}
                          pathname={"/account/products/"}
                        />
                      </>
                    )}
                  </div>
                ) : (
                  <div className="card mt-3">
                    <div className="card-body">
                      <p className="nothing">No Products found</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <ModalComponent
        open={openModal}
        toggle={toggleModal}
        size="lg"
        title={` ${id ? "Edit" : "Add"} Product`}
      >
        <div className="login_form">
          <form className="tagForm" onSubmit={submitHandler}>
            <label className="label">Name</label>
            <input
              type="text"
              value={name}
              className="form-control mb-3"
              required
              onChange={(e) => setName(e.target.value)}
            />

            <label className="label">Price</label>
            <input
              type="number"
              value={price}
              required
              className="form-control mb-3"
              onChange={(e) => setPrice(e.target.value)}
            />

            <label className="label">Old Price</label>
            <input
              type="number"
              value={old_price}
              className="form-control mb-3"
              onChange={(e) => setOld_Price(e.target.value)}
            />

            <label className="label">Quantity of Product</label>
            <input
              type="number"
              required
              value={quantity}
              className="form-control mb-3"
              onChange={(e) => setQuantity(e.target.value)}
            />

            {/* gender starts */}
            <label className="label">Gender</label>
            <select
              onChange={(e) => setGender(e.target.value)}
              className="form-select mb-3"
              required
              value={gender}
            >
              <option value="" disabled selected>
                -- Select Option --
              </option>
              {GenderItems?.map((item, index: number) => (
                <option key={index} value={item.value}>
                  {item.name}
                </option>
              ))}
            </select>
            {/* gender ends */}

            <label className="label">Category</label>
            <select
              onChange={(e) => setCategory(e.target.value)}
              className="form-select mb-3"
              required
              value={category}
            >
              <option value="" disabled selected>
                -- Select Option --
              </option>
              {categories?.map((item, index: number) => (
                <option key={index} value={item._id}>
                  {item.name}
                </option>
              ))}
            </select>

            {/* sub category starts */}
            <label className="label">Sub Category Name</label>
            <select
              onChange={(e) => setSubCategory_name(e.target.value)}
              className="form-select mb-3"
              required
              defaultValue={subCategory_name}
            >
              <option value="">-- Select Option --</option>
              {sub_category?.map((item, index: number) => (
                <option key={index} value={item._id}>
                  {item.name}
                </option>
              ))}
            </select>
            {/* sub category ends */}

            <label className="label">Brand Name</label>
            <select
              onChange={(e) => setBrand_name(e.target.value)}
              className="form-select mb-3"
              // required
              defaultValue={brand_name}
            >
              <option value="">-- Select Option --</option>
              {brands?.map((item, index: number) => (
                <option key={index} value={item._id}>
                  {item.name}
                </option>
              ))}
            </select>

            {/* size starts */}
            <div>
              <label className="label">Size</label>
              <div className="mb-3 sizeBox ">
                {SizeItems.map((item, index) => (
                  <div className="sizeItem" key={index}>
                    <input
                      type="checkbox"
                      id={item.value}
                      value={item.name}
                      onChange={handleCheckboxChange}
                      checked={sizes.includes(item.name)}
                    />
                    <label htmlFor={item.value}>{item.name}</label>
                  </div>
                ))}
              </div>
              {/* You can display the selected sizes */}
              {sizes?.length > 0 && <p>Selected Sizes: {sizes.join(", ")}</p>}
            </div>

            {/* color n starts */}
            <div>
              <label className="label">Color</label>
              <div className="mb-3 sizeBox ">
                {ColorItems.map((item) => (
                  <div className="sizeItem" key={item.id}>
                    <input
                      type="checkbox"
                      id={item.value}
                      value={item.name}
                      onChange={handleColorCheckboxChange}
                      checked={colors.includes(item.name)}
                    />
                    <label htmlFor={item.value}>{item.name}</label>
                  </div>
                ))}
              </div>
              {/* You can display the selected sizes */}
              {colors.length > 0 && (
                <div>
                  Selected Colors:
                  <ul className="colorBox">
                    {colors?.map((color, index) => (
                      <div>
                        <li
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                          className=" "
                        >
                          <div
                            style={{
                              backgroundColor: color,
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              border: "1px black solid"
                            }}
                          ></div>
                          <span>{color}</span>
                        </li>
                        {/* <button className="remove-color" onClick={() => handleRemoveColor(color)}>Remove</button> */}
                      </div>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {/* color n ends */}

            {/* <label className="label">Vendor</label>
            <select
              onChange={(e) => setVendor(e.target.value)}
              className="form-select mb-3"
              // required
              defaultValue={vendor}
            >
              <option value="">-- Select Option --</option>
              {users_no_pag?.users
                ?.filter(
                  (item: UserInterface) =>
                    item.type === "VENDOR" && item.active,
                )
                .map((item, index: number) => (
                  <option key={index} className="black" value={item._id}>
                    {item?.firstName} {item?.lastName}
                  </option>
                ))}
            </select> */}

            <label className="label">Description</label>
            <textarea
              value={description}
              className="form-control mb-3"
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>

            <label className="label">New Product</label>
            <input
              type="checkbox"
              defaultChecked={new_product}
              className="mb-3 ms-2"
              onChange={(e) => setNew_product((prev) => !prev)}
            />
            <br />

            <div className="facilities__images listing_form">
            <div>
                  <label className="facilities__images-text">
                    Product Images (Please select a maximum of 6 images)
                  </label>

                  <input
                    type="file"
                    multiple
                    className="form-control mt-2 "
                    // onChange={(e) => setImage(e.target.files[0])}
                    onChange={(e) => handleFileInputChange(e)}
                    style={{ borderBottom: "none" }}
                  />
                  {/* {mainImage?.length > 0 && (
                    <p
                      onClick={deleteMainImage}
                      className="mt-2"
                      style={{
                        fontSize: "11.5px",
                        textDecoration: "underline",
                      }}
                    >
                      <i className="fa fa-trash" style={{ color: "red" }}></i>{" "}
                      Clear all selected images{" "}
                    </p>
                  )} */}
                </div>
              {fileLength < 1 && id && (
                <div className=" mt-4 ">
                  <p>Already Uploaded Images</p>
                  <div className=" d-flex  flex-wrap ">
                    {/* {uploadedImg.map((image, index) => (
                      <div className="w-25 p-2 " >
                        <img className=" w-100 mb-2 " src={image} alt="" />
                      </div>
                    ))} */}
                    <DragDrop images={uploadedImg} onOrderChange={handleOrderChange} />
                  </div>
                </div>
              )}
              {/* {imageFile && (
                <div className="col-md-12">
                  <div className="mt-1">
                    <button
                      className="btn btn-primary"
                      onClick={(e) => uploadFileHandler(e)}
                      disabled={uploading ? true : false}
                    >
                      Upload Images{" "}
                      {uploading && <i className="fas fa-spinner fa-spin"></i>}
                    </button>
                  </div>
                </div>
              )} */}

              <input
                type="text"
                value={uploadedImage}
                onChange={(e) => setUploadedImage(e.target.value)}
                className="site-form mb-3 d-none"
              />
            </div>

            <div className="d-flex justify-content-between mt-4">
              <div>
                <button disabled={is_loading} className="btn btn_site_dark">
                  {is_fetching ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : is_loading ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : id ? (
                    "Update"
                  ) : (
                    "Create"
                  )}
                </button>
                <p style={{ fontSize: "12px", color: "red" }}> {message} </p>
              </div>
              {id && (
                <div>
                  <button
                    className="btn btn_site"
                    onClick={statusUpdateHandler}
                    disabled={is_loading ? true : false}
                  >
                    Update Status
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      </ModalComponent>
    </section>
  )
}

export default DashboardProduct
